import React, { useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { SideBar } from '../../../components/side-bar'
import { navLinksProduct } from '../../../services'
import {
    models
} from '../../../store/slices'
import { useAppDispatch } from '../../../store'
import { useSelector } from 'react-redux'
import { IModel } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'
import { getIdFromIRI } from '../../../services/_common/utils'
import { DataTable } from '../../../components/dataTable'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../constants'


export function ModelsPage(): JSX.Element {

    const dispatch = useAppDispatch()
    const fetchStatus = useSelector(models.selectors.fetchStatus)
    const totalItems = useSelector(models.selectors.totalItems)

    useEffect(() => {
        dispatch(models.actions.fetch({pagination: false}))
    }, [])

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksProduct}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.PRODUCT_MODELS_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<IModel, FetchCreatedAtParams & FetchCollectionParams>
                title={'Модели'}
                urlPathEdit={'/product/models/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={models.actions.fetch}
                selector={models.selectors.selectAll}
                deleteAction={models.actions.remove}
                mappingFunction={(model) => ({
                    id: getIdFromIRI(model.id),
                    name: model.name,
                    description: model?.description || '',
                })}
                loading={fetchStatus.isFetching}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true },
                    { field: 'name', headerName: 'Название', flex: 1, sortable: true },
                    { field: 'description', headerName: 'Описание', flex: 1, sortable: true },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}