import { Field } from 'formik'
import { Grid } from '@material-ui/core'
import React from 'react'
import { DataTableFilter } from './types'
import { SelectFieldMultiple } from '../selectFieldMultiple'
import { DatePicker } from 'formik-material-ui-pickers'
import { DatePeriodField } from '../datePeriodField'

export function Filter (props: DataTableFilter): JSX.Element {
    switch (props.type) {
        case 'selectMultiple':
            return (
                <Grid item xs={2}>
                    <Field
                        component={SelectFieldMultiple}
                        fullWidth
                        name={props.name}
                        label={props.label}
                        items={props.items}
                    />
                </Grid>
            )

        case 'date':
            return (
                <Grid item xs={2}>
                    <Field
                        component={DatePicker}
                        variant={'standard'}
                        name={props.name}
                        label={props.label}
                        fullWidth
                        disabled={false} // а хуй его знает, но по умолчанию true
                    />
                </Grid>
            )

        case 'datePeriod':
            return (
                <Grid item xs={4}>
                    <Field
                        component={DatePeriodField}
                        variant={'standard'}
                        name={props.name}
                        label={props.label}
                        fullWidth
                    />
                </Grid>
            )

        default:
            return <></>
    }
}