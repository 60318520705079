import { IProvider } from '../../types'
import { TApiProvider, TApiProviders } from '../../../services/organization/types'
import { userTransformer } from '../users/utils'
import { ICollection } from '../../../services/_common'

export function parseProviders(response: TApiProviders): ICollection<IProvider> {
    return {
        entities: response.entities.map(providerTransformer),
        totalItems: response.totalItems
    }
}

export const providerTransformer = (apiObject: TApiProvider): IProvider => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt,
})