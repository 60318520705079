import React, { ChangeEvent, useCallback } from 'react'
import { useStyles } from './select-field.style'
import { fieldToTextField, TextFieldProps } from 'formik-material-ui'
import MuiTextField from '@material-ui/core/TextField'
import { MenuItem } from '@material-ui/core'
import { SimpleEvent } from '../../services/_common'

const NULL_VALUE = '---NULL---'

export interface SelectFieldProps extends TextFieldProps {
    nullable: boolean,
    nullableText: string,
    items: Array<{
        value: string,
        label: string
    }>,
    onChangeSimple: (event: SimpleEvent) => void
}

export function SelectField({
    nullable,
    nullableText,
    items,
    ...props
}: SelectFieldProps): JSX.Element {
    const classes = useStyles()

    const {
        form: { setFieldValue },
        field: { name, value },
    } = props

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            props.onChangeSimple && props.onChangeSimple({target: { name: name, value: value }})
            setFieldValue(name, (nullable && value === NULL_VALUE) ? null : value)
        },
        [setFieldValue, name],
    )

    return (
        <MuiTextField
            {...fieldToTextField(props)}
            name={name}
            value={nullable && (value === null || value === undefined) ? NULL_VALUE : items && items.length ? ((value === null || value === undefined) ? '' : value?.id || value ) : ''}
            onChange={onChange}
            className={classes.selectField}
            select
        >
            {nullable && <MenuItem value={NULL_VALUE}><i>{nullableText}</i></MenuItem>}
            {items.map((item, idx) => <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>)}
        </MuiTextField>
    )
}