import { TAuthState, TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'

const selectState = (state: TRootState): TAuthState => state.auth

const user = createSelector(
    selectState,
    (state) => state.user
)

const token = createSelector(
    selectState,
    (state) => state.token
)

const signInStatus = createSelector(
    selectState,
    (state) => ({
        error: state.signInError,
        fetching: state.signInFetching
    })
)

export const selectors = {
    user,
    token,
    signInStatus,
}
