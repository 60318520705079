import { ITransaction } from '../../types'
import { TApiTransaction, TApiTransactions } from '../../../services/moneyFlow/types'
import { ICollection } from '../../../services/_common'

export function parseTransactions(response: TApiTransactions): ICollection<ITransaction> {
    return {
        entities: response.entities.map(transactionTransformer),
        totalItems: response.totalItems
    }
}

export const transactionTransformer = (apiObject: TApiTransaction): ITransaction => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    from: {
        ...apiObject.from,
        billType: {
            id: apiObject.from.type['@id'],
            text: apiObject.from.type.text
        }
    },
    to: {
        ...apiObject.to,
        billType: {
            id: apiObject.to.type['@id'],
            text: apiObject.to.type.text
        }
    },
    credit: apiObject.credit,
    debit: apiObject.debit,
    status: {
        id: apiObject.status['@id'],
        text: apiObject.status.text
    },
    createdAt: apiObject.createdAt,
    creator: apiObject.creator,
})