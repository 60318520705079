import { httpService, HydraCollectionResponse } from '../_common'
import { TApiUser, TApiUsers } from './types'
import { mapHydraCollection } from '../_common/utils'

export const usersService = {
    fetch: async (): Promise<TApiUsers> => {
        const response = await httpService.request<HydraCollectionResponse<TApiUser>>({
            url: '/users',
            method: 'get',
        })

        return mapHydraCollection(response.data)
    }
}