import React from 'react'
import {
    Box,
    Button,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { businessUnits } from '../../../store/slices'
import { SideBar } from '../../../components/side-bar'
import { navLinksOrganization, renderUtils } from '../../../services'
import { Link } from 'react-router-dom'
import { DataTable } from '../../../components/dataTable'
import { ROUTES } from '../../../constants'
import { IBusinessUnit } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'

export function BusinessUnitsPage(): JSX.Element {
    const fetchStatus = useSelector(businessUnits.selectors.fetchStatus)
    const totalItems = useSelector(businessUnits.selectors.totalItems)

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksOrganization}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.ORGANIZATION_BUSINESS_UNITS_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<IBusinessUnit, FetchCreatedAtParams & FetchCollectionParams>
                title={'БЮ'}
                urlPathEdit={'/organization/business-units/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={businessUnits.actions.fetch}
                deleteAction={businessUnits.actions.remove}
                selector={businessUnits.selectors.selectAll}
                mappingFunction={(businessUnit) => ({
                    id: businessUnit.id,
                    name: businessUnit.name,
                    active: businessUnit.active,
                })}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1 },
                    { field: 'name', headerName: 'Название', flex: 1 },
                    { field: 'active', headerName: 'Активность', flex: 1, type: 'boolean', renderCell: renderUtils.renderBoolean },
                ]}
                loading={fetchStatus.isFetching}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}