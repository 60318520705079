import { TRootState, TTransactionStatusesState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { transactionStatusesAdapter } from './adapter'

const selectState = (state: TRootState): TTransactionStatusesState => state.transactionStatuses

const adapterSelectors = transactionStatusesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus
}