import { GridCellParams } from '@material-ui/data-grid'
import { Link } from 'react-router-dom'
import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import RemoveIcon from '@material-ui/icons/Remove'
import CheckIcon from '@material-ui/icons/Check'
import * as React$1 from 'react'
import { GridCellExpand } from '../../components/dataTable/ExpandCell'
import moment from 'moment'


const renderCellId = (params: GridCellParams, urlPath: string, field = 'id'): React$1.ReactElement => {
    return (
        <>
            <Link to={urlPath + params.getValue(field)}><EditIcon/></Link>
        </>
    )
}

const renderDate = (params: GridCellParams): JSX.Element => {
    if(typeof params.value == 'string') {
        return (
            <>{moment(params.value).format('L')}</>
        )
    } else {
        return <></>
    }
}

const renderDateTime = ({ value }: GridCellParams): JSX.Element => {
    if(typeof value == 'string') {
        return (
            <>{moment(value).format('L LT')}</>
        )
    } else {
        return <></>
    }
}

const renderBoolean = ({ value }: GridCellParams): React$1.ReactElement => {
    if(value)
        return <CheckIcon/>
    else
        return <RemoveIcon/>
}

const renderCellExpand = (params: GridCellParams): JSX.Element => {
    return (
        <GridCellExpand
            value={params.value ? params.value.toString() : ''}
            width={params.colDef.width}
        />
    )
}

export const renderUtils = {
    renderCellId,
    renderBoolean,
    renderCellExpand,
    renderDate,
    renderDateTime,
}