import React from 'react'
import {
    Box,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    transactions,
} from '../../../store/slices'
import { SideBar } from '../../../components/side-bar'
import { navLinksMoneyFlow, renderUtils } from '../../../services'
import { getIdFromIRI } from '../../../services/_common/utils'
import { DataTable } from '../../../components/dataTable'
import { ITransaction } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'

export function TransactionsPage(): JSX.Element {
    const fetchStatus = useSelector(transactions.selectors.fetchStatus)
    const totalItems = useSelector(transactions.selectors.totalItems)

    return (
        <Box height={'100%'} display={'flex'}>
            <SideBar navLinks={navLinksMoneyFlow}>

            </SideBar>
            <DataTable<ITransaction, FetchCreatedAtParams & FetchCollectionParams>
                title={'Транзакции'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={transactions.actions.fetch}
                selector={transactions.selectors.selectAll}
                mappingFunction={(transaction) => ({
                    id: getIdFromIRI(transaction.id),
                    name: `${transaction.from.name} -> ${transaction.to.name}`,
                    amount: `${transaction.credit.amount} ${transaction.credit.currency} | ${transaction.debit.amount} ${transaction.debit.currency}`,
                    status: transaction.status.text,
                    creator: transaction.creator.name,
                    createdAt: transaction.createdAt
                })}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, hide: true },
                    { field: 'name', headerName: 'Название', flex: 1 },
                    { field: 'amount', headerName: 'Кдерит | Дебит', flex: 1 },
                    { field: 'status', headerName: 'Статус', flex: 1 },
                    { field: 'creator', headerName: 'Пользователь', flex: 1 },
                    { field: 'createdAt', headerName: 'Дата создания', flex: 1, renderCell: renderUtils.renderDateTime},
                ]}
                loading={fetchStatus.isFetching}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}