import React from 'react'
import { RouteProps, Switch, Route } from 'react-router-dom'
import { NotFoundPage } from '../../pages/not-found'
import { PrivateRoute } from '../../components/private-route'
import { ROUTES } from '../../constants'
import { BusinessUnitsPage } from '../../pages/organization/businessUnit'
import { BusinessUnitCreatePage } from '../../pages/organization/businessUnit/create'
import { BusinessUnitEditPage } from '../../pages/organization/businessUnit/edit'
import { JuridicalPersonsPage } from '../../pages/organization/juridicalPersons'
import { JuridicalPersonEditPage } from '../../pages/organization/juridicalPersons/edit'
import { JuridicalPersonCreatePage } from '../../pages/organization/juridicalPersons/create'
import { ProvidersPage } from '../../pages/organization/providers'
import { ProviderCreatePage } from '../../pages/organization/providers/create'
import { ProviderEditPage } from '../../pages/organization/providers/edit'

const privateRoutes: Array<RouteProps> = [
    {
        path: ROUTES.ORGANIZATION,
        component: BusinessUnitsPage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_BUSINESS_UNITS,
        component: BusinessUnitsPage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_BUSINESS_UNITS_CREATE,
        component: BusinessUnitCreatePage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_BUSINESS_UNITS_EDIT,
        component: BusinessUnitEditPage,
    },
    {
        path: ROUTES.ORGANIZATION_JURIDICAL_PERSONS,
        component: JuridicalPersonsPage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_JURIDICAL_PERSONS_CREATE,
        component: JuridicalPersonCreatePage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_JURIDICAL_PERSONS_EDIT,
        component: JuridicalPersonEditPage,
    },
    {
        path: ROUTES.ORGANIZATION_PROVIDERS,
        component: ProvidersPage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_PROVIDERS_CREATE,
        component: ProviderCreatePage,
        exact: true
    },
    {
        path: ROUTES.ORGANIZATION_PROVIDERS_EDIT,
        component: ProviderEditPage,
    }
]

export function Organization(): JSX.Element {
    return (
        <Switch>
            {privateRoutes.map((route) => <PrivateRoute key={String(route.path)} {...route} />)}
            <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
        </Switch>
    )
}
