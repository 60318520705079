import { TRootState, TPaymentTypesState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { paymentTypesAdapter } from './adapter'

const selectState = (state: TRootState): TPaymentTypesState => state.paymentTypes

const adapterSelectors = paymentTypesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus
}