import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parseTest } from './utils'
import { saleService } from '../../../services'
import { TSaleState } from '../../types'
import { parseHttpError } from '../../utils'
import { selectors } from './selectors'

const name = 'sale'

const initialState: TSaleState = {
    isFetching: false
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async () => parseTest(await saleService.fetch()),
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.data = action.payload
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const sale = {
    name,
    actions: {
        fetch,
    },
    reducer: slice.reducer,
    selectors,
}