import { ISpending } from '../../types'
import { TApiSpending } from '../../../services/moneyFlow/types'
import moment from 'moment'
import { userTransformer } from '../users/utils'
import { businessUnitTransformer } from '../businessUnits/utils'
import { enumTransformer } from '../../../services/_common/utils'
import { juridicalPersonTransformer } from '../juridicalPersons/utils'
import { ICollection, TApiCollection } from '../../../services/_common'
import { subSpendingTypeTransformer } from '../subSpendingTypes/utils'
import { spendingTypeTransformer } from '../spendingTypes/utils'
import { providerTransformer } from '../provider/utils'



export function parseSpendings(response: TApiCollection<TApiSpending>): ICollection<ISpending> {
    return {
        entities: response.entities.map(spendingTransformer),
        totalItems: response.totalItems
    }
}

export const spendingTransformer = (apiObject: TApiSpending): ISpending => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    amount: apiObject.amount,
    businessUnit: businessUnitTransformer(apiObject.businessUnit),
    spendingType: spendingTypeTransformer(apiObject.spendingType),
    subSpendingType: apiObject.subSpendingType ? subSpendingTypeTransformer(apiObject.subSpendingType) : null,
    paymentType: enumTransformer(apiObject.paymentType),
    debtDeal: (apiObject.debtDeal) ? spendingTransformer(apiObject.debtDeal) : null,
    actualDate: moment(apiObject.actualDate).format(),
    comment: apiObject.comment,
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt,
    juridicalPerson: apiObject.juridicalPerson ? juridicalPersonTransformer(apiObject.juridicalPerson) : null,
    provider: apiObject.provider ? providerTransformer(apiObject.provider) : null,
})