import React, { useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { SideBar } from '../../../components/side-bar'
import { navLinksProduct } from '../../../services/product'
import {
    categories
} from '../../../store/slices'
import { useAppDispatch } from '../../../store'
import { useSelector } from 'react-redux'
import { ICategory } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'
import { getIdFromIRI } from '../../../services/_common/utils'
import { DataTable } from '../../../components/dataTable'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../constants'


export function CategoriesPage(): JSX.Element {

    const dispatch = useAppDispatch()
    const fetchStatus = useSelector(categories.selectors.fetchStatus)
    const totalItems = useSelector(categories.selectors.totalItems)

    useEffect(() => {
        dispatch(categories.actions.fetch({pagination: false}))
    }, [])

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksProduct}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.PRODUCT_CATEGORIES_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<ICategory, FetchCreatedAtParams & FetchCollectionParams>
                title={'Категории'}
                urlPathEdit={'/product/categories/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={categories.actions.fetch}
                selector={categories.selectors.selectAll}
                deleteAction={categories.actions.remove}
                mappingFunction={(category) => ({
                    id: getIdFromIRI(category.id),
                    name: category.name,
                    marked: category.marked ? 'Да' : 'Нет',
                    parent: category.parent?.name || ''
                })}
                loading={fetchStatus.isFetching}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true },
                    { field: 'name', headerName: 'Название', flex: 1, sortable: true },
                    { field: 'marked', headerName: 'Маркировка', flex: 1, sortable: true },
                    { field: 'parent', headerName: 'Родитель', flex: 1, sortable: true },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}