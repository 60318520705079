import { makeStyles } from '@material-ui/core'
import { theme } from '../../theme'

export const useStyles = makeStyles(() => ({
    input: {
        display: 'none'
    },
    loading: {
        width: 20,
        height: 20
    },
    icon: {
        backgroundColor: theme.palette.primary.main
    },
    grow: {
        flexGrow: 1
    }
}))