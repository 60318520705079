import React, { useEffect, useMemo } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { useStyles } from './productPriceField'
import { useAppDispatch } from '../../store'
import {
    priceTypes,
} from '../../store/slices'
import { useSelector } from 'react-redux'
import { SelectField } from '../selectField'
import { Field } from 'formik'
import { validation } from '../../services'
import { NumericField } from '../numericField'
import { DateField } from '../dateField'
import { MoneyField } from '../moneyField'

interface ProductPriceFieldProps {
    prefix?: string,

}

export const emptyProductPrice = {
    type: null,
    actionValue: null,
    actionPrice: null,
    dateStart: null,
    dateEnd: null,
    model: null,
    price: null,
    costPrice: null,
}

export function ProductPriceField(props : ProductPriceFieldProps) : JSX.Element {

    const classes = useStyles()

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(priceTypes.actions.fetch())
    }, [])

    const priceTypesList = useSelector(priceTypes.selectors.selectAll)

    const priceTypesItems = useMemo(() => priceTypesList.map((item) => ({value: item.iri, label: item.text})), [priceTypesList])

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Field
                        label={'Тип'}
                        name={`${props.prefix}type`}
                        component={SelectField}
                        required
                        fullWidth
                        nullable
                        nullableText={'Не выбрано'}
                        items={priceTypesItems}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name={`${props.prefix}costPrice`}
                        component={MoneyField}
                        validate={validation.isPositive}
                        type="number"
                        label="Цена закупки"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name={`${props.prefix}price`}
                        component={MoneyField}
                        validate={validation.isPositive}
                        type="number"
                        label="Цена продажи"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        label="Акция старт"
                        name={`${props.prefix}dateStart`}
                        component={DateField}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        label="Акция конец"
                        name={`${props.prefix}dateEnd`}
                        component={DateField}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name={`${props.prefix}actionPrice`}
                        component={NumericField}
                        validate={validation.isPositive}
                        type="number"
                        label="Новая цена"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name={`${props.prefix}actionValue`}
                        component={NumericField}
                        validate={validation.isPositive}
                        type="number"
                        label="Или скидка в %"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}