import { TCategoriesState, TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { categoriesAdapter } from './adapter'

const selectState = (state: TRootState): TCategoriesState => state.categories

const adapterSelectors = categoriesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
}