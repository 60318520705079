import React, { useCallback } from 'react'
import { Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { auth } from '../../store/slices'
import { useForm } from 'react-hook-form'
import { useStyles } from './sign-in.style'
import { useAppDispatch } from '../../store'
import { ROUTES } from '../../constants'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'

export function SignInPage(): JSX.Element {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { error, fetching } = useSelector(auth.selectors.signInStatus)
    const { handleSubmit, register, errors } = useForm()

    const onSubmit = useCallback((data) => {
        dispatch(auth.actions.signIn(data))
            .then(unwrapResult)
            .then(() => {
                // TODO: Делаль редирект на изначально запрошенный URL
                history.push(ROUTES.ROOT)
            })
    }, [dispatch])

    return (
        <Container maxWidth="xs">
            <Paper elevation={4} className={classes.form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="Email"
                        name="email"
                        inputRef={register({ required: true })}
                        fullWidth
                        className={classes.fieldMargin}
                        helperText={errors.email && 'Required field'}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        name="password"
                        inputRef={register({ required: true })}
                        fullWidth
                        className={classes.fieldMargin}
                        helperText={errors.password && 'Required field'}
                    />
                    {error && <Typography color="error" align="center">{error.message}</Typography>}
                    <Button type="submit" disabled={fetching} fullWidth color="primary">Login</Button>
                </form>
            </Paper>
        </Container>
    )
}