import { TModelsState, TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { modelsAdapter } from './adapter'

const selectState = (state: TRootState): TModelsState => state.models

const adapterSelectors = modelsAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
}