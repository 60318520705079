import React, { useEffect } from 'react'
import {
    Box,
    Button, Divider,
    Grid,
} from '@material-ui/core'
import { options } from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { ROUTES } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksProduct, validation } from '../../../../services'
import { Field, FieldArray, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { FileField } from '../../../../components/fileField'

export function  OptionCreatePage(): JSX.Element {
    useEffect(() => {
        dispatch(options.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()

    const emptyValue = {
        value: ''
    }

    const initialValues = {
        name: '',
        description: '',
        values: []
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(options.actions.create(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.PRODUCT_OPTIONS)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm, values: option }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksProduct}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Создание опции'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Описание"
                                        name="description"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <FieldArray
                                name="values"
                                render={(arrayHelpers) => (
                                    <>
                                        {option.values.map((value, index) => (
                                            <Grid key={index} container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name={`values[${index}].value`}
                                                        component={TextField}
                                                        label="Значение"
                                                        fullWidth
                                                        validate={validation.required}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name={`values[${index}].image`}
                                                        id={`${index}image`}
                                                        isImage
                                                        component={FileField}
                                                        label="Картинка"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button
                                                        fullWidth={true}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Убрать
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{margin: '15 0'}} />
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Button
                                            fullWidth={true}
                                            style={{marginTop: 30}}
                                            onClick={() => arrayHelpers.push(emptyValue)}
                                        >
                                            Добавить возможное значение
                                        </Button>
                                    </>
                                )}
                            />
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}