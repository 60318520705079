import React, { useCallback } from 'react'
import { fieldToTextField, TextFieldProps } from 'formik-material-ui'
import NumericInput from 'material-ui-numeric-input'
import { SimpleEvent } from '../../services/_common'

interface SelectFieldProps extends TextFieldProps {
    label?: string|undefined,
    helperText?: string|undefined,
    onChangeSimple: (event: SimpleEvent) => void
}

export function NumericField({
    label,
    helperText,
    ...props
}: SelectFieldProps): JSX.Element {

    const {
        form: { setFieldValue },
        field: { name, value },
    } = props

    const onChange = useCallback(
        (value) => {
            props.onChangeSimple && props.onChangeSimple({target: { name: name, value: value }})
            setFieldValue(name, value)
        },
        [setFieldValue, name],
    )

    return (
        <NumericInput
            {...fieldToTextField(props)}
            label={label ?? ''}
            helperText={helperText}
            name={name}
            value={value}
            onChange={onChange}
            precision='2'
            decimalSeparator=','
            thousandSeparator='.'
            allowNegative={false}
        />
    )
}