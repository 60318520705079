import { TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { TBillMoneysState } from '../../types'
import { billMoneysAdapter } from './adapter'

const selectState = (state: TRootState): TBillMoneysState => state.billMoneys

const adapterSelectors = billMoneysAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus
}