import React from 'react'
import { useStyles } from './not-found.style'
import { Container, Typography } from '@material-ui/core'

export function NotFoundPage(): JSX.Element {
    const classes = useStyles()

    return (
        <Container maxWidth="sm" className={classes.root}>
            <div>
                <div className={classes.title}>404</div>
                <Typography variant="subtitle1">Страница не найдена</Typography>
            </div>
        </Container>
    )
}