import { IUser } from '../../types'
import { TApiUser, TApiUsers } from '../../../services/users/types'

export function parseUsers(response: TApiUsers): IUser[] {
    return response.entities.map(userTransformer)
}

export const userTransformer = (apiObject: TApiUser): IUser => ({
    id: apiObject.id,
    name: apiObject.name,
    email: apiObject.email,
})