import {
    AttributeGroupProps,
    FetchAttributeProps,
    IAttributeGroup,
    TAttributeGroupsState,
} from '../../types'
import { attributeGroupsAdapter } from './adapter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchCollectionParams, FetchCreatedAtParams, httpService, UpdateEntityProps } from '../../../services/_common'
import { parseHttpError } from '../../utils'
import { parseAttributeGroups } from './utils'
import { TApiAttributeGroup } from '../../../services/product/types'
import { selectors } from './selectors'
import { productService } from '../../../services/product'


const name = 'attributeGroups'

const initialState: TAttributeGroupsState = {
    ...attributeGroupsAdapter.getInitialState(),
    isFetching: false,
    totalItems: 0,
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async (params: FetchCreatedAtParams & FetchCollectionParams | undefined) => {
        return httpService.fetchCollection<TApiAttributeGroup, IAttributeGroup, FetchAttributeProps>({
            url: '/attribute_groups',
            params,
            parseFunction: parseAttributeGroups
        })
    },
    {
        serializeError: parseHttpError
    }
)

const create = createAsyncThunk(
    `${name}/create`,
    async (attributeGroup: AttributeGroupProps) => {
        return await productService.createAttributeGroup(attributeGroup)
    },
    {
        serializeError: parseHttpError
    }
)

const update = createAsyncThunk(
    `${name}/create`,
    async (attributeGroup: UpdateEntityProps<AttributeGroupProps>) => {
        return await productService.updateAttributeGroup(attributeGroup)
    },
    {
        serializeError: parseHttpError
    }
)

const remove = createAsyncThunk(
    `${name}/remove`,
    async (id: string) => {
        return await productService.removeAttributeGroup(id)
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.totalItems = action.payload.totalItems
                attributeGroupsAdapter.setAll(state, action.payload.entities)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const attributeGroups = {
    name: slice.name,
    actions: {
        fetch,
        create,
        update,
        remove,
    },
    reducer: slice.reducer,
    selectors,
}