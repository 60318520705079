import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
} from '@material-ui/core'
import { categories } from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { ROUTES } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { validation } from '../../../../services'
import { Field,  Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { navLinksProduct } from '../../../../services/product'
import { useSelector } from 'react-redux'
import { SelectField } from '../../../../components/selectField'

export function CategoryCreatePage(): JSX.Element {
    useEffect(() => {
        dispatch(categories.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()

    const parentsList = useSelector(categories.selectors.selectAll)

    const initialValues = {
        name: '',
        marked: false,
        parent: null
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(categories.actions.create(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.PRODUCT_CATEGORIES)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksProduct}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Создание категории'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'Маркировка' }}
                                        name="marked"
                                        type="checkbox"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={SelectField}
                                        label="Родитель"
                                        name="parent"
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={parentsList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}