import { IJuridicalPerson } from '../../types'
import { TApiJuridicalPerson, TApiJuridicalPersons } from '../../../services/organization/types'
import { userTransformer } from '../users/utils'
import { ICollection } from '../../../services/_common'

export function parseJuridicalPersons(response: TApiJuridicalPersons): ICollection<IJuridicalPerson> {
    return {
        entities: response.entities.map(juridicalPersonTransformer),
        totalItems: response.totalItems
    }
}

export const juridicalPersonTransformer = (apiObject: TApiJuridicalPerson): IJuridicalPerson => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    longName: apiObject.longName,
    juridicalAddress: apiObject.juridicalAddress,
    address: apiObject.address,
    email: apiObject.email,
    phone: apiObject.phone,
    ogrn: apiObject.ogrn,
    inn: apiObject.inn,
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt,
})