import React from 'react'
import { IconButton } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import { ActionCellProps } from './types'
import { useFormikContext } from 'formik'
import { useAppDispatch } from '../../store'


export function ActionCell({
    deleteActions,
    urlPathEdit,
    editAction,
    ...props
}: ActionCellProps): JSX.Element {
    const dispatch = useAppDispatch()
    const id = props.getValue('id')
    const { submitForm } = useFormikContext()

    const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { value } = event.currentTarget
        if(deleteActions && value) {
            dispatch(deleteActions(value))
            return submitForm()
        }
    }

    const handleEdit = () => {
        if (editAction) {
            props.row && editAction(props.row)
        }
    }

    if (typeof id == 'string') {
        return (<>
            {urlPathEdit && !editAction ? <IconButton component={Link} to={`${urlPathEdit}/${id}`}>
                <EditIcon/>
            </IconButton> : null}

            {editAction && <IconButton onClick={handleEdit}>
                <EditIcon/>
            </IconButton>}

            {deleteActions ? <IconButton
                onClick={handleRemove}
                value={id}
            >
                <DeleteIcon/>
            </IconButton> : null}

        </>)
    } else {
        return (<strong>id in not string</strong>)
    }
}