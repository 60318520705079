import React from 'react'
import { RouteProps, Route, Switch, Redirect } from 'react-router-dom'
import { NotFoundPage } from '../../pages/not-found'
import { SignInPage } from '../../pages/sign-in'
import { PrivateRoute } from '../../components/private-route'
import { ROUTES } from '../../constants'
import { SalePage } from '../../pages/sale'
import { TopBar } from '../../components/top-bar'
import { useStyles } from './app.style'
import { MoneyFlow } from '../money-flow'
import { Organization } from '../organization'
import { Product } from '../product'

const RedirectToMoneyFlow = () => <Redirect to={ROUTES.MONEY_FLOW}/>

const privateRoutes: Array<RouteProps> = [
    {
        path: ROUTES.ROOT,
        exact: true,
        render: RedirectToMoneyFlow
    },
    {
        path: ROUTES.SALE,
        component: SalePage
    },
    {
        path: ROUTES.MONEY_FLOW,
        component: MoneyFlow
    },
    {
        path: ROUTES.ORGANIZATION,
        component: Organization
    },
    {
        path: ROUTES.PRODUCT,
        component: Product
    }
]

const publicRoutes: Array<RouteProps> = [
    {
        path: ROUTES.SIGN_IN,
        component: SignInPage
    },
    {
        path: ROUTES.NOT_FOUND,
        component: NotFoundPage
    }
]

export function App(): JSX.Element {
    const classes = useStyles()
    return (
        <>
            <TopBar />
            <div className={classes.pagesContainer}>
                <Switch>
                    {privateRoutes.map((route) => <PrivateRoute key={String(route.path)} {...route} />)}
                    {publicRoutes.map((route) => <Route key={String(route.path)} {...route} />)}
                </Switch>
            </div>
        </>
    )
}
