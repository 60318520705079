import { ISubSpendingType } from '../../types'
import { TApiSubSpendingType, TApiSubSpendingTypes } from '../../../services/moneyFlow/types'
import { userTransformer } from '../users/utils'
import { ICollection } from '../../../services/_common'
import { spendingTypeTransformer } from '../spendingTypes/utils'

export function parseSubSpendingTypes(response: TApiSubSpendingTypes): ICollection<ISubSpendingType> {
    return {
        entities: response.entities.map(subSpendingTypeTransformer),
        totalItems: response.totalItems
    }
}

export const subSpendingTypeTransformer = (apiObject: TApiSubSpendingType) : ISubSpendingType => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    active: apiObject.active,
    spendingType: spendingTypeTransformer(apiObject.spendingType),
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt
})