import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
        minWidth: 0, // So the Typography noWrap works

        '@media print': {
            backgroundColor: 'transparent',
        },
    },
    contentPaper: {
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
    },
    contentTitle: {
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
    }
}))