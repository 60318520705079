import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
    },
    wrapper: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
        minWidth: 0, // So the Typography noWrap works

        '@media print': {
            backgroundColor: 'transparent',
        },
    },
    tableContainerWrapper: { height: 400, width: '100%' },
    tableContainer: { display: 'flex', height: '100%' },
    tableWrapper: {flexGrow: 1}
}))