import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { prices } from '../../../../store/slices'
import { Formik } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { ProductPriceField } from '../../../../components/productPriceField'

interface PriceEditModalProps {
    id: string,
    onClose: (ok: boolean) => void
}

export function PriceEditModal (props: PriceEditModalProps) : JSX.Element {

    useEffect(() => {
        dispatch(prices.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()

    const price = useAppSelector((state) => prices.selectors.selectById(state, props.id))

    const initialValues = {
        ...price,
        iri: price?.iri || '',
        id: price?.id || '',
        type: price?.type?.iri || '',
        actionValue: price?.actionValue || null,
        actionPrice: price?.actionPrice || null,
        dateStart: price?.dateStart || null,
        dateEnd: price?.dateEnd || null,
        model: price?.model || '',
        product: price?.product || '',
        price: price?.price || null,
        costPrice: price?.costPrice || null,
    }

    const handleClose = (ok: boolean) => () => props.onClose(ok)

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(prices.actions.update({ iri: values.iri, data: values }))
                    .then(unwrapResult)
                    .then(() => {
                        props.onClose(true)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Dialog open={true} onClose={handleClose(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Редактировать</DialogTitle>
                    <DialogContent>

                        <ProductPriceField prefix='' />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose(false)} color="secondary">
                            Отмена
                        </Button>
                        <Button onClick={submitForm} color="primary">
                            Сохранить
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )

}