import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { BrowserRouter } from 'react-router-dom'
import { App } from './containers/app'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { theme } from './theme'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
