import { modelPresetsAdapter } from './adapter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchCollectionParams, FetchCreatedAtParams, httpService, UpdateEntityProps } from '../../../services/_common'
import { parseHttpError } from '../../utils'
import { parseModelPresets } from './utils'
import { TApiModelPreset } from '../../../services/product/types'
import { selectors } from './selectors'
import { productService } from '../../../services'
import { FetchModelPresetProps, IModelPreset, ModelPresetProps, TModelPresetsState } from '../../types/modelPreset'


const name = 'modelPresetss'

const initialState: TModelPresetsState = {
    ...modelPresetsAdapter.getInitialState(),
    isFetching: false,
    totalItems: 0,
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async (params: FetchCreatedAtParams & FetchCollectionParams | undefined) => {
        return httpService.fetchCollection<TApiModelPreset, IModelPreset, FetchModelPresetProps>({
            url: '/model_presets',
            params,
            parseFunction: parseModelPresets
        })
    },
    {
        serializeError: parseHttpError
    }
)

const create = createAsyncThunk(
    `${name}/create`,
    async (modelPreset: ModelPresetProps) => {
        return await productService.createModelPreset(modelPreset)
    },
    {
        serializeError: parseHttpError
    }
)

const update = createAsyncThunk(
    `${name}/create`,
    async (modelPreset: UpdateEntityProps<ModelPresetProps>) => {
        return await productService.updateModelPreset(modelPreset)
    },
    {
        serializeError: parseHttpError
    }
)

const remove = createAsyncThunk(
    `${name}/remove`,
    async (id: string) => {
        return await productService.removeModelPreset(id)
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.totalItems = action.payload.totalItems
                modelPresetsAdapter.setAll(state, action.payload.entities)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const modelPresets = {
    name: slice.name,
    actions: {
        fetch,
        create,
        update,
        remove,
    },
    reducer: slice.reducer,
    selectors,
}