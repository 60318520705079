import { IBusinessUnit } from '../../types'
import { TApiBusinessUnit, TApiBusinessUnits } from '../../../services/organization/types'
import { userTransformer } from '../users/utils'
import { ICollection } from '../../../services/_common'

export function parseBusinessUnits(response: TApiBusinessUnits): ICollection<IBusinessUnit> {
    return {
        entities: response.entities.map(businessUnitTransformer),
        totalItems: response.totalItems
    }
}

export const businessUnitTransformer = (apiObject: TApiBusinessUnit): IBusinessUnit => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    active: apiObject.active,
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt,
})