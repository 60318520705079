import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
} from '@material-ui/core'
import { spendingTypes, subSpendingTypes } from '../../../../store/slices'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksMoneyFlow, validation } from '../../../../services'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { SelectField } from '../../../../components/selectField'
import { useSelector } from 'react-redux'

interface SubSpendingTypeEditPageProps {
    match: {
        params: {
            id: string
        }
    }
}

export function SubSpendingTypeEditPage(props: SubSpendingTypeEditPageProps): JSX.Element {
    useEffect(() => {
        dispatch(spendingTypes.actions.fetch({pagination: false}))
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()
    const spendingTypesList = useSelector(spendingTypes.selectors.selectActive)
    const subSpendingType = useAppSelector((state) => subSpendingTypes.selectors.selectById(state, props.match.params.id))

    const initialValues = {
        id: subSpendingType?.iri || '',
        name: subSpendingType?.name || '',
        active: subSpendingType?.active || false,
        spendingType: subSpendingType?.spendingType.iri || ''
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(subSpendingTypes.actions.update({iri: values.id, data: values}))
                    .then(unwrapResult)
                    .then(() => {
                        history.goBack()
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksMoneyFlow}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Редактирование подстатьи расхода'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'Активна' }}
                                        name="active"
                                        type="checkbox"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label='Статья расхода'
                                        name='spendingType'
                                        component={SelectField}
                                        validate={validation.required}
                                        required
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={spendingTypesList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}