import React, { useCallback } from 'react'
import { fieldToTextField, TextFieldProps } from 'formik-material-ui'
import NumericInput from 'material-ui-numeric-input'

interface MoneyFieldProps extends TextFieldProps {
    label?: string|undefined,
    helperText?: string|undefined,
}

export function MoneyField({
    label,
    helperText,
    ...props
}: MoneyFieldProps): JSX.Element {

    const {
        form: { setFieldValue },
        field: {
            name,
            value
        }
    } = props

    const onChange = useCallback(
        (value) => {
            setFieldValue(name, value !== undefined && value !== null ? {
                amount: value * 100,
                currency: 'RUB'
            } : undefined)
        },
        [setFieldValue, name],
    )

    const valueDecimal = value?.amount ? value.amount / 100 : undefined

    return (
        <NumericInput
            {...fieldToTextField(props)}
            label={label ?? ''}
            helperText={helperText}
            name={name}
            value={valueDecimal}
            onChange={onChange}
            endAdornment='₽'
            precision='2'
            decimalSeparator='.'
            thousandSeparator=' '
            allowNegative={false}
        />
    )
}