import { TAttributeGroupsState, TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { attributeGroupsAdapter } from './adapter'

const selectState = (state: TRootState): TAttributeGroupsState => state.attributeGroups

const adapterSelectors = attributeGroupsAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
}