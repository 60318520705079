import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
} from '@material-ui/core'
import { spendingTypes, expenditureTypes } from '../../../../store/slices'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksMoneyFlow, validation } from '../../../../services'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { SelectField } from '../../../../components/selectField'
import { useSelector } from 'react-redux'

interface SpendingTypeEditPageProps {
    match: {
        params: {
            id: string
        }
    }
}

export function SpendingTypeEditPage(props: SpendingTypeEditPageProps): JSX.Element {
    useEffect(() => {
        dispatch(expenditureTypes.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()

    const spendingType = useAppSelector((state) => spendingTypes.selectors.selectById(state, props.match.params.id))
    const expenditureTypesList = useSelector(expenditureTypes.selectors.selectAll)

    const initialValues = {
        id: spendingType?.iri || '',
        name: spendingType?.name || '',
        expenditureType: spendingType?.expenditureType.iri || '',
        active: spendingType?.active || false,
        requiredJuridicalPerson: spendingType?.requiredJuridicalPerson || false
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(spendingTypes.actions.update({iri: values.id, data: values}))
                    .then(unwrapResult)
                    .then(() => {
                        history.goBack()
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksMoneyFlow}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Редактирование статьи расхода'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={SelectField}
                                        label="Тип расхода"
                                        name="expenditureType"
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={expenditureTypesList.map((item) => ({value: item.iri, label: item.text}))}
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'Активна' }}
                                        name="active"
                                        type="checkbox"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'Обязательно указание юр.лица' }}
                                        name="requiredJuridicalPerson"
                                        type="checkbox"
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}