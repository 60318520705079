import React, { RefObject, useCallback, useState } from 'react'
import { fieldToTextField, TextFieldProps } from 'formik-material-ui'
import { Avatar, CircularProgress, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useStyles } from './file-field.style'
import { baseURL, commonService } from '../../services/_common'
import ImageIcon from '@material-ui/icons/Image'

interface FileFieldProps extends TextFieldProps {
    label?: string|undefined,
    helperText?: string|undefined,
    id?: string|undefined,
    isImage?: boolean|undefined,
    onRemove?: () => void|undefined
}

export function FileField({
    label,
    helperText,
    isImage,
    onRemove,
    ...props
}: FileFieldProps): JSX.Element {

    const classes = useStyles()

    const input: RefObject<HTMLInputElement> = React.createRef()

    const {
        form: { setFieldValue },
        field: {
            name,
            value
        },
        id,
    } = props

    const [loading, setLoading] = useState(false)

    // const choseFile = () => {
    //     input.current.handleClick()
    // }
    //
    useCallback(
        (value) => {
            setFieldValue(name, value)
        },
        [setFieldValue, name],
    )

    const handleClick = () => {
        input.current?.click()
    }

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files?.length) {
            return
        }

        setLoading(true)

        commonService.uploadFile( event.target.files[0] )
            .then((response) => {
                setLoading(false)
                setFieldValue(name, response)
            })
    }

    const removeFile = (event: React.SyntheticEvent) => {
        event.stopPropagation()
        setFieldValue(name, null)
        onRemove && onRemove()
    }

    const inputId = 'input' + id

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
                {isImage && !!value?.path ?
                    <Avatar src={baseURL+value.path} />
                    :
                    <Avatar className={classes.icon}>
                        { isImage ? <ImageIcon/> : <AttachFileIcon/> }
                    </Avatar>
                }
            </Grid>

            <Grid item className={classes.grow}>
                <TextField
                    {...fieldToTextField(props)}
                    label={label ?? ''}
                    helperText={helperText}
                    name={name}
                    type="text"
                    onClick={ handleClick }
                    InputProps={{
                        endAdornment: !value?.name ? null : <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={removeFile}
                            >
                                {loading ? <CircularProgress className={classes.loading}/> : <DeleteForeverIcon/>}
                            </IconButton>
                        </InputAdornment>,
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        htmlFor: inputId,
                        shrink: !!value?.name
                    }}
                    value={value?.name || ''}
                />
                <input
                    id={inputId}
                    type="file"
                    className={classes.input}
                    value=""
                    onChange={handleFileSelect}
                    ref={input}
                />
            </Grid>
        </Grid>
    )
}


