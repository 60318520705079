import { TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { modelPresetsAdapter } from './adapter'
import { TModelPresetsState } from '../../types/modelPreset'

const selectState = (state: TRootState): TModelPresetsState => state.modelPresets

const adapterSelectors = modelPresetsAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
}