import { TApiStorage, TApiStorages } from '../../../services/product/types'
import { ICollection } from '../../../services/_common'
import { IStorage } from '../../types'
import { userTransformer } from '../users/utils'

export function parseStorages(response: TApiStorages): ICollection<IStorage> {
    return {
        entities: response.entities.map(storageTransformer),
        totalItems: response.totalItems
    }
}

export const storageTransformer = (apiObject: TApiStorage) : IStorage => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    lat: apiObject.lat,
    long: apiObject.long,
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt
})