import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import {
    FetchAction,
    DeleteAction,
    MappingFunction,
    SelectorFunction,
    ExportFunction,
    DataTableFilter, EditAction,
} from './types'
import {
    DataGrid,
    DataGridProps,
    GridCellParams,
    GridOverlay,
    GridPageChangeParams,
    GridSortModelParams,
} from '@material-ui/data-grid'
import { LinearProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ActionCell } from './action-cell'
import { Toolbar } from './Toolbar'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string-object'
import { FetchCollectionParams } from '../../services/_common'

export function Table<Entity, FetchProps extends FetchCollectionParams>({
    columns,
    selector,
    mappingFunction,
    exportMappingFunction,
    headerExportColumns,
    deleteActions,
    editAction,
    urlPathEdit,
    exportFunction,
    loading,
    filters,
    ...props
}: {
    dataFunction: FetchAction<Entity, FetchProps>,
    selector: SelectorFunction<Entity>,
    mappingFunction: MappingFunction<Entity>,
    exportMappingFunction?: MappingFunction<Entity>,
    headerExportColumns?: Array<string>,
    deleteActions?: DeleteAction,
    editAction?: EditAction,
    urlPathEdit?: string,
    exportFunction?: ExportFunction<Entity, FetchProps>,
    filters?: Array<DataTableFilter>|undefined,
    loading: boolean,
} & DataGridProps): JSX.Element {
    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    function CustomToolbar() {
        return (
            <Toolbar columns={columns} exportMappingFunction={exportMappingFunction || mappingFunction} headerExportColumns={headerExportColumns} loading={loading} exportFunction={exportFunction} filters={filters}/>
        )
    }

    const {
        values,
        setFieldValue,
        submitForm,
    } = useFormikContext<FetchProps>()
    const {page, perPage} = values
    const history = useHistory()
    const location = useLocation()
    const entities = useSelector(selector)
    const [cells, setCells] = useState(columns)
    const [rows, setRows] = useState<{[key: number]: unknown|undefined}[]>(entities.map((entity) => mappingFunction(entity)))

    useEffect(() => {
        setRows(entities.map((entity) => mappingFunction(entity)))
    }, [entities])

    useEffect(() => {
        if(deleteActions || urlPathEdit || editAction) {
            setCells([
                ...cells,
                {
                    field: 'dataTableRowActions',
                    sortable: false,
                    headerName: 'Действия',
                    headerAlign: 'center',
                    width: 150,
                    flex: 0,
                    align: 'center',
                    renderCell: function renderCell(params: GridCellParams): JSX.Element {
                        return <ActionCell
                            {...params}
                            deleteActions={deleteActions}
                            urlPathEdit={urlPathEdit}
                            editAction={editAction}
                        />
                    },
                }
            ])
        }

    }, [deleteActions, urlPathEdit])

    useEffect(() => {
        history.push({
            pathname: location.pathname,
            search: qs.stringify(values)
        })
        submitForm()
    }, [values])

    const onPageSizeChange = (params: GridPageChangeParams) => {
        setFieldValue('perPage', params.pageSize )
        if(params.page > 1) {
            setFieldValue('page', 1)
            history.push({
                pathname: location.pathname,
                search: qs.stringify({
                    ...qs.parse(location.search),
                    page: 1
                })
            })
        }
    }

    const onPageChange = (params: GridPageChangeParams) => {
        setFieldValue('page', params.page + 1)
    }

    const onSortModelChange = (params: GridSortModelParams) => {
        setFieldValue('order', params.sortModel.reduce((acc: {[key: string]: unknown|undefined }, value) => {
            acc[value.field] = value.sort
            return acc
        }, {}))
    }

    return (<DataGrid
        {...props}
        columns={cells}
        rows={rows}
        pagination
        disableSelectionOnClick
        paginationMode={'server'}
        pageSize={Number(perPage)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        page={page ? page - 1: page}
        disableColumnMenu={true}
        sortingMode="server"
        autoHeight={true}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        onSortModelChange={onSortModelChange}
        components={{
            LoadingOverlay: CustomLoadingOverlay,
            Toolbar: CustomToolbar
        }}
    />)
}