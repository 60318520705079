import { httpService, HydraCollectionResponse } from '../_common'
import {
    TApiBusinessUnit,
    TApiBusinessUnits,
    TApiJuridicalPerson,
    TApiJuridicalPersons,
    TApiProvider,
    TApiProviders,
} from './types'
import { mapHydraCollection } from '../_common/utils'
import { ROUTES } from '../../constants'
import {
    BusinessUnitUpdateProps,
    JuridicalPersonProps,
    JuridicalPersonUpdateProps, ProviderProps, ProviderUpdateProps,
} from '../../store/types'
import { AxiosResponse } from 'axios'
import * as querystring from 'query-string'

export const navLinksOrganization = [
    { path: ROUTES.ORGANIZATION_BUSINESS_UNITS, text: 'БЮ', role: 'USER'},
    { path: ROUTES.ORGANIZATION_JURIDICAL_PERSONS, text: 'Юр. лица', role: 'USER'},
    { path: ROUTES.ORGANIZATION_PROVIDERS, text: 'Поставщики', role: 'USER'},
]

export const organizationService = {
    fetchBusinessUnits: async (params = {}): Promise<TApiBusinessUnits> => {
        const response = await httpService.request<HydraCollectionResponse<TApiBusinessUnit>>({
            url: '/business_units',
            method: 'get',
            params: params,
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, skipEmptyString: true})
            }
        })

        return mapHydraCollection(response.data)
    },

    createBusinessUnit: async (businessUnit = {}): Promise<TApiBusinessUnit> => {
        const response = await httpService.request<TApiBusinessUnit>({
            url: '/business_units',
            method: 'POST',
            data: businessUnit
        })

        return response.data
    },

    updateBusinessUnit: async (businessUnit: BusinessUnitUpdateProps): Promise<TApiBusinessUnit> => {
        const response = await httpService.request<TApiBusinessUnit>({
            url: `/business_units/${businessUnit.id}`,
            method: 'PUT',
            data: businessUnit,
        })

        return response.data
    },

    removeBusinessUnit: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/business_units/${id}`,
            method: 'DELETE',
        })
    },

    fetchJuridicalPersons: async (params = {}): Promise<TApiJuridicalPersons> => {
        const response = await httpService.request<HydraCollectionResponse<TApiJuridicalPerson>>({
            url: '/juridical_people',
            method: 'get',
            params: params,
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, skipEmptyString: true})
            }
        })

        return mapHydraCollection(response.data)
    },

    createJuridicalPerson: async (data: JuridicalPersonProps): Promise<TApiJuridicalPerson> => {
        const response = await httpService.request<TApiJuridicalPerson>({
            url: '/juridical_people',
            method: 'POST',
            data
        })

        return response.data
    },

    updateJuridicalPerson: async (data: JuridicalPersonUpdateProps): Promise<TApiJuridicalPerson> => {
        const response = await httpService.request<TApiJuridicalPerson>({
            url: `/juridical_people/${data.id}`,
            method: 'PUT',
            data
        })

        return response.data
    },

    removeJuridicalPerson: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/juridical_people/${id}`,
            method: 'DELETE',
        })
    },


    fetchProviders: async (params = {}): Promise<TApiProviders> => {
        const response = await httpService.request<HydraCollectionResponse<TApiProvider>>({
            url: '/providers',
            method: 'get',
            params: params,
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, skipEmptyString: true})
            }
        })

        return mapHydraCollection(response.data)
    },

    createProvider: async (data: ProviderProps): Promise<TApiProvider> => {
        const response = await httpService.request<TApiProvider>({
            url: '/providers',
            method: 'POST',
            data
        })

        return response.data
    },

    updateProvider: async (data: ProviderUpdateProps): Promise<TApiProvider> => {
        const response = await httpService.request<TApiProvider>({
            url: `/providers/${data.id}`,
            method: 'PUT',
            data
        })

        return response.data
    },

    removeProvider: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/providers/${id}`,
            method: 'DELETE',
        })
    },
}