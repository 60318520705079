import { ISubSpendingType, TRootState, TSubSpendingTypesState } from '../../types'
import { createSelector, OutputSelector } from '@reduxjs/toolkit'
import { subSpendingTypesAdapter } from './adapter'

const selectState = (state: TRootState): TSubSpendingTypesState => state.subSpendingTypes

const adapterSelectors = subSpendingTypesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


const selectActive = createSelector(
    adapterSelectors.selectAll,
    (state) => state.filter((item) => item.active)
)



const selectBySpendingTypeId = (id: string|null): OutputSelector<TRootState, ISubSpendingType[], (res: ISubSpendingType[]) => ISubSpendingType[]> => {
    return createSelector(
        adapterSelectors.selectAll,
        (state) => state.filter((item) => item.active && item.spendingType.id == id)
    )
}

export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
    selectActive,
    selectBySpendingTypeId
}