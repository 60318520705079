import {
    FetchCollectionParams, FetchCreatedAtParam,
    FetchCreatedAtParams,
    httpService,
    HydraCollectionResponse, ICollection,
    TApiEnum,
    TApiEnums,
    UpdateEntityProps,
} from '../_common'
import {
    TApiBill,
    TApiBills,
    TApiDeal,
    TApiDeals,
    TApiSpending,
    TApiSpendingType,
    TApiSpendingTypes, TApiSubSpendingType, TApiSubSpendingTypes,
    TApiTransaction,
    TApiTransactions,
} from './types'
import { mapHydraCollection } from '../_common/utils'
import { ROUTES } from '../../constants'
import * as querystring from 'query-string'
import { CreateSpendingProps, CreateSpendingsProps } from '../../store/slices'
import { ISpending, SpendingTypeProps, SubSpendingTypeProps } from '../../store/types'
import { AxiosResponse } from 'axios'
import { parseSpendings } from '../../store/slices/spendings/utils'

export const navLinksMoneyFlow = [
    { path: ROUTES.MONEY_FLOW, text: 'ДДС', role: 'USER'},
    { path: ROUTES.MONEY_FLOW_SPENDINGS, text: 'Расходы', role: 'USER'},
    { path: ROUTES.MONEY_FLOW_SPENDING_TYPES, text: 'Статьи расходов', role: 'USER'},
    { path: ROUTES.MONEY_FLOW_SUB_SPENDING_TYPES, text: 'Подстатьи расходов', role: 'USER'},
]

export interface FetchTransactionsParams {
    businessUnit: Array<string>
}

export const moneyFlowService = {
    fetchBills: async (): Promise<TApiBills> => {
        const response = await httpService.request<HydraCollectionResponse<TApiBill>>({
            url: '/bills',
            method: 'get',
        })

        return mapHydraCollection(response.data)
    },

    createBills: async (bill: TApiBill): Promise<TApiBill> => {
        const response = await httpService.request<TApiBill>({
            url: '/bills',
            method: 'POST',
            data: bill
        })

        return response.data
    },

    fetchBillTypes: async (): Promise<TApiEnums> => {
        const response = await httpService.request<HydraCollectionResponse<TApiEnum>>({
            url: '/bill_types',
            method: 'get',
        })
        return mapHydraCollection(response.data)
    },

    fetchPaymentTypes: async (): Promise<TApiEnums> => {
        const response = await httpService.request<HydraCollectionResponse<TApiEnum>>({
            url: '/payment_types',
            method: 'get',
        })
        return mapHydraCollection(response.data)
    },

    fetchExpenditureTypes: async (): Promise<TApiEnums> => {
        const response = await httpService.request<HydraCollectionResponse<TApiEnum>>({
            url: '/expenditure_types',
            method: 'get',
        })
        return mapHydraCollection(response.data)
    },

    fetchSpendingTypes: async (params = {}): Promise<TApiSpendingTypes> => {
        const response = await httpService.request<HydraCollectionResponse<TApiSpendingType>>({
            url: '/spending_types',
            method: 'get',
            params: params,
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, skipEmptyString: true})
            }
        })

        return mapHydraCollection(response.data)
    },

    createSpendingType: async (spendingType = {}): Promise<TApiSpendingType> => {
        const response = await httpService.request<TApiSpendingType>({
            url: '/spending_types',
            method: 'POST',
            data: spendingType
        })

        return response.data
    },

    updateSpendingType: async (updateEntityProps: UpdateEntityProps<SpendingTypeProps>): Promise<TApiSpendingType> => {
        const response = await httpService.request<TApiSpendingType>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeSpendingType: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/spending_types/${id}`,
            method: 'DELETE',
        })
    },

    fetchSubSpendingTypes: async (params = {}): Promise<TApiSubSpendingTypes> => {
        const response = await httpService.request<HydraCollectionResponse<TApiSubSpendingType>>({
            url: '/sub_spending_types',
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, skipEmptyString: true})
            }
        })

        return mapHydraCollection(response.data)
    },

    createSubSpendingType: async (spendingType = {}): Promise<TApiSubSpendingType> => {
        const response = await httpService.request<TApiSubSpendingType>({
            url: '/sub_spending_types',
            method: 'POST',
            data: spendingType
        })

        return response.data
    },

    updateSubSpendingType: async (updateEntityProps: UpdateEntityProps<SubSpendingTypeProps>): Promise<TApiSubSpendingType> => {
        const response = await httpService.request<TApiSubSpendingType>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeSubSpendingType: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/sub_spending_types/${id}`,
            method: 'DELETE',
        })
    },

    createSpending: async (spendings: CreateSpendingProps): Promise<TApiSpending> => {
        const response = await httpService.request<TApiSpending>({
            url: '/deals/create-spending',
            method: 'POST',
            data: spendings
        })

        return response.data
    },

    createSpendings: async (spending: CreateSpendingsProps): Promise<TApiSpending> => {
        const response = await httpService.request<TApiSpending>({
            url: '/deals/create-spendings',
            method: 'POST',
            data: spending
        })

        return response.data
    },

    fetchSpendings: async (params: {
        businessUnit?: Array<string>|undefined
        paymentType?: Array<string>|undefined
        spendingType?: Array<string>|undefined
        subSpendingType?: Array<string>|undefined
        juridicalPerson?: Array<string>|undefined
        provider?: Array<string>|undefined
        actualDate?: FetchCreatedAtParam
    } & FetchCreatedAtParams & FetchCollectionParams | undefined): Promise<ICollection<ISpending>> => {

        const response = await httpService.request<HydraCollectionResponse<TApiSpending>>({
            url: '/deals',
            method: 'get',
            params: {
                businessUnit: params?.businessUnit,
                paymentType: params?.paymentType,
                spendingType: params?.spendingType,
                subSpendingType: params?.subSpendingType,
                provider: params?.provider,
                juridicalPerson: params?.juridicalPerson,
                pagination: params?.pagination,
                page: params?.page,
                perPage: params?.perPage,
                'createdAt[before]': params?.createdAt?.before,
                'createdAt[after]': params?.createdAt?.after,
                'actualDate[before]': params?.actualDate?.before,
                'actualDate[after]': params?.actualDate?.after,
                ...params?.order || {'order[createdAt]': 'desc'}
            },
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, strict: true, skipEmptyString: true})
            }
        })

        return parseSpendings(mapHydraCollection(response.data))
    },

    removeSpending: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/deals/${id}`,
            method: 'DELETE',
        })
    },


    fetchDeals: async (): Promise<TApiDeals> => {
        const response = await httpService.request<HydraCollectionResponse<TApiDeal>>({
            url: '/deals',
            method: 'get',
        })

        return mapHydraCollection(response.data)
    },

    fetchTransactions: async (params = {}): Promise<TApiTransactions> => {
        const response = await httpService.request<HydraCollectionResponse<TApiTransaction>>({
            url: '/transactions',
            method: 'get',
            params: params,
            paramsSerializer: (params) => {
                return querystring.stringify(params, {arrayFormat: 'bracket', skipNull: true, skipEmptyString: true})
            }
        })

        return mapHydraCollection(response.data)
    },

    fetchTransactionStatuses: async (): Promise<TApiEnums> => {
        const response = await httpService.request<HydraCollectionResponse<TApiEnum>>({
            url: '/transaction_statuses',
            method: 'get',
        })

        return mapHydraCollection(response.data)
    },
}