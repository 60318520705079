import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from '../../../services'
import { TAuthState } from '../../types'
import { selectors } from './selectors'
import { parseToken } from './utils'
import { parseHttpError } from '../../utils'
import { LS_JWT_TOKEN } from '../../../constants'

const name = 'auth'

const initialState: TAuthState = {
    signInFetching: false,
    user: null,
    token: null,
    roles: null,
}

type TSignInPayload = {
    email: string,
    password: string
}
const signIn = createAsyncThunk(
    `${name}/signIn`,
    async ({ email, password }: TSignInPayload ) => {
        const data = parseToken(await authService.signIn(email, password))
        //Сохраним ещё токен отдельно для удобства
        window.localStorage.setItem(LS_JWT_TOKEN, data.token)
        return data
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {
        signOut: (state) => {
            state.token = null
            state.user = null
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(signIn.pending, (state) => {
                state.signInFetching = true
                state.signInError = undefined
                state.token = null
                state.user = null
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.signInFetching = false
                const { token, email, id, name, roles } = action.payload
                state.token = token
                state.user = { email, name, id }
                state.roles = roles
            })
            .addCase(signIn.rejected, (state, action) => {
                state.signInFetching = false
                state.signInError = action.error
            })
})

export const auth = {
    name: slice.name,
    actions: {
        ...slice.actions,
        signIn,
    },
    reducer: slice.reducer,
    selectors
}
