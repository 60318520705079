import React from 'react'
import {
    Box,
    Button, Divider,
    Grid,
} from '@material-ui/core'
import { options } from '../../../../store/slices'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import {  validation } from '../../../../services'
import { Field, FieldArray, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { FileField } from '../../../../components/fileField'
import { navLinksProduct } from '../../../../services'

interface OptionEditPageProps {
    match: {
        params: {
            id: string
        }
    }
}

export function OptionEditPage(props: OptionEditPageProps): JSX.Element {


    const dispatch = useAppDispatch()
    const history = useHistory()

    const option = useAppSelector((state) => options.selectors.selectById(state, props.match.params.id))


    const initialValues = {
        id: option?.iri || '',
        name: option?.name || '',
        description: option?.description || '',
        values: option?.values || [],
    }

    const emptyValue = {
        value: ''
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(options.actions.update({iri: values.id, data: values}))
                    .then(unwrapResult)
                    .then(() => {
                        history.goBack()
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm, values: option }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksProduct}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Редактирование опции'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Описание"
                                        name="description"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <FieldArray
                                name="values"
                                render={(arrayHelpers) => (
                                    <>
                                        {option.values.map((value, index) => (
                                            <Grid key={index} container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name={`values[${index}].value`}
                                                        component={TextField}
                                                        label="Значение"
                                                        fullWidth
                                                        validate={validation.required}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name={`values[${index}].image`}
                                                        isImage
                                                        component={FileField}
                                                        label="Картинка"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button
                                                        fullWidth={true}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Убрать
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{margin: '15 0'}} />
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Button
                                            fullWidth={true}
                                            style={{marginTop: 30}}
                                            onClick={() => arrayHelpers.push(emptyValue)}
                                        >
                                            Добавить возможное значение
                                        </Button>
                                    </>
                                )}
                            />
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}