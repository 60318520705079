import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TTransactionsState } from '../../types'
import { moneyFlowService } from '../../../services'
import { parseHttpError } from '../../utils'
import { parseTransactions } from './utils'
import { selectors } from './selectors'
import { transactionsAdapter } from './adapter'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'

const name = 'transactions'

const initialState: TTransactionsState = {
    ...transactionsAdapter.getInitialState(),
    isFetching: false,
    totalItems: 0,
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async (params: {
        businessUnit?: Array<string>
    } & FetchCreatedAtParams & FetchCollectionParams | undefined) => {

        const order = params?.order || {'order[createdAt]': 'desc'}

        return parseTransactions(await moneyFlowService.fetchTransactions({
            pagination: params?.pagination,
            page: params?.page,
            perPage: params?.perPage,
            ...order
        }))
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.totalItems = action.payload.totalItems
                transactionsAdapter.setAll(state, action.payload.entities)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const transactions = {
    name: slice.name,
    actions: {
        fetch,
    },
    reducer: slice.reducer,
    selectors,
}