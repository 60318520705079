import React, { useCallback } from 'react'
import { AppBar, Divider, Drawer, IconButton, List, Toolbar, Typography, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { auth } from '../../store/slices'
import { NAV_LINKS } from '../../constants'
import { useStyles } from './top-bar.style'
import { AccountCircle } from '@material-ui/icons'
import { useAppDispatch } from '../../store'

export function TopBar(): JSX.Element {
    const user = useSelector(auth.selectors.user)
    const classes = useStyles()
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const dispatch = useAppDispatch()

    const handleDrawerOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleDrawerClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleSignOut = useCallback(() => {
        dispatch(auth.actions.signOut())
    }, [dispatch])

    return (
        <>
            <AppBar
                position="static"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    {user &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    }
                    <Typography variant="h6" noWrap className={classes.grow}>
                        MG
                    </Typography>
                    {user && (
                        <div>
                            {user?.name} <IconButton color="inherit" onClick={handleSignOut}><AccountCircle/></IconButton>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    {NAV_LINKS.map((navLink) => (
                        <ListItem
                            key={navLink.path}
                            button
                            component={Link}
                            to={navLink.path}
                            onClick={handleDrawerClose}
                        >
                            <ListItemText primary={navLink.text}/>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    )
}