import { TRootState, TModelOptionTypesState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { modelOptionTypesAdapter } from './adapter'

const selectState = (state: TRootState): TModelOptionTypesState => state.modelOptionTypes

const adapterSelectors = modelOptionTypesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus
}