import {
    StorageProps,
    FetchAttributeProps,
    IStorage,
    TStoragesState,
} from '../../types'
import { storagesAdapter } from './adapter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchCollectionParams, FetchCreatedAtParams, httpService, UpdateEntityProps } from '../../../services/_common'
import { parseHttpError } from '../../utils'
import { parseStorages } from './utils'
import { TApiStorage } from '../../../services/product/types'
import { selectors } from './selectors'
import { productService } from '../../../services/product'


const name = 'storages'

const initialState: TStoragesState = {
    ...storagesAdapter.getInitialState(),
    isFetching: false,
    totalItems: 0,
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async (params: FetchCreatedAtParams & FetchCollectionParams | undefined) => {
        return httpService.fetchCollection<TApiStorage, IStorage, FetchAttributeProps>({
            url: '/storages',
            params,
            parseFunction: parseStorages
        })
    },
    {
        serializeError: parseHttpError
    }
)

const create = createAsyncThunk(
    `${name}/create`,
    async (storage: StorageProps) => {
        return await productService.createStorage(storage)
    },
    {
        serializeError: parseHttpError
    }
)

const update = createAsyncThunk(
    `${name}/create`,
    async (storage: UpdateEntityProps<StorageProps>) => {
        return await productService.updateStorage(storage)
    },
    {
        serializeError: parseHttpError
    }
)

const remove = createAsyncThunk(
    `${name}/remove`,
    async (id: string) => {
        return await productService.removeStorage(id)
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.totalItems = action.payload.totalItems
                storagesAdapter.setAll(state, action.payload.entities)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const storages = {
    name: slice.name,
    actions: {
        fetch,
        create,
        update,
        remove,
    },
    reducer: slice.reducer,
    selectors,
}