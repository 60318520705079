import { TRootState, TSaleState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { users } from '../users'

const selectState = (state: TRootState): TSaleState => state.sale

const data = createSelector(selectState, (state) => state.data)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        error: state.fetchError,
        isFetching: state.isFetching
    })
)

const dataWithUser = createSelector(
    data,
    users.selectors.selectEntities,
    (sale, users) => ({
        ...sale,
        user: sale ? users[sale.userId] : undefined
    })
)

export const selectors = {
    dataWithUser,
    fetchStatus,
}
