import { ICollection } from '../../../services/_common'
import { ICategory } from '../../types'
import { TApiCategory, TApiCategories } from '../../../services/product/types'


export function parseCategories(response: TApiCategories): ICollection<ICategory> {
    return {
        entities: response.entities.map(categoryTransformer),
        totalItems: response.totalItems
    }
}

export const categoryTransformer = (apiObject: TApiCategory) : ICategory => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    marked: apiObject.marked,
    parent: apiObject.parent ? categoryTransformer(apiObject.parent) : null,
})