import { ICollection } from '../../../services/_common'
import {
    TApiOption,
    TApiOptions,
    TApiOptionValue,
} from '../../../services/product/types'
import { IOption } from '../../types/option'
import { IOptionValue } from '../../types/optionValue'
import { fileTransformer } from '../../utils'


export function parseOptions(response: TApiOptions): ICollection<IOption> {
    return {
        entities: response.entities.map(optionTransformer),
        totalItems: response.totalItems
    }
}

export const optionTransformer = (apiObject: TApiOption) : IOption => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    description: apiObject.description,
    values: apiObject.values.map(optionValueTransformer),
})

export const optionValueTransformer = (apiObject: TApiOptionValue) : IOptionValue => ({
    id: apiObject.id,
    iri: apiObject['@id'],
    value: apiObject.value,
    image: apiObject.image ? fileTransformer(apiObject.image) : null,
})
