import React from 'react'
import { RouteProps, Switch, Route } from 'react-router-dom'
import { NotFoundPage } from '../../pages/not-found'
import { PrivateRoute } from '../../components/private-route'
import { ROUTES } from '../../constants'
import { SpendingCreatePage } from '../../pages/money-flow/spendings/create'
import { SpendingTypeCreatePage } from '../../pages/money-flow/spendingTypes/create'
import { SpendingTypesPage } from '../../pages/money-flow/spendingTypes'
import { SpendingsPage } from '../../pages/money-flow/spendings'
import { TransactionsPage } from '../../pages/money-flow/transactions'
import { SpendingTypeEditPage } from '../../pages/money-flow/spendingTypes/edit'
import { SpendingEditPage } from '../../pages/money-flow/spendings/edit'
import { SubSpendingTypesPage } from '../../pages/money-flow/subSpendingTypes'
import { SubSpendingTypeCreatePage } from '../../pages/money-flow/subSpendingTypes/create'
import { SubSpendingTypeEditPage } from '../../pages/money-flow/subSpendingTypes/edit'

const privateRoutes: Array<RouteProps> = [
    {
        path: ROUTES.MONEY_FLOW,
        component: TransactionsPage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SPENDINGS,
        component: SpendingsPage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SPENDINGS_CREATE,
        component: SpendingCreatePage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SPENDINGS_EDIT,
        component: SpendingEditPage,
    },
    {
        path: ROUTES.MONEY_FLOW_SPENDING_TYPES,
        component: SpendingTypesPage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SPENDING_TYPES_CREATE,
        component: SpendingTypeCreatePage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SPENDING_TYPES_EDIT,
        component: SpendingTypeEditPage,
    },
    {
        path: ROUTES.MONEY_FLOW_SUB_SPENDING_TYPES,
        component: SubSpendingTypesPage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SUB_SPENDING_TYPES_CREATE,
        component: SubSpendingTypeCreatePage,
        exact: true
    },
    {
        path: ROUTES.MONEY_FLOW_SUB_SPENDING_TYPES_EDIT,
        component: SubSpendingTypeEditPage,
    },
]

export function MoneyFlow(): JSX.Element {
    return (
        <Switch>
            {privateRoutes.map((route) => <PrivateRoute key={String(route.path)} {...route} />)}
            <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
        </Switch>
    )
}
