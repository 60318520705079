import { userTransformer } from '../slices/users/utils'
import { IFile, TApiFile } from '../../services/_common'

export const fileTransformer = (apiObject: TApiFile): IFile => {
    return {
        iri: apiObject['@id'],
        id: apiObject.id,
        name: apiObject.name,
        size: apiObject.size,
        path: apiObject.path,
        creator: userTransformer(apiObject.creator),
        createdAt: apiObject.createdAt,
    }
}