import React from 'react'
import {
    Box,
    Button,
    Grid,
} from '@material-ui/core'
import { juridicalPersons } from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { ROUTES } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksOrganization, validation } from '../../../../services'
import { Field, Form, Formik } from 'formik'
import { JuridicalPersonProps } from '../../../../store/types'
import { TextField } from 'formik-material-ui'

export function JuridicalPersonCreatePage(): JSX.Element {
    const dispatch = useAppDispatch()
    const history = useHistory()

    const initialValues: JuridicalPersonProps = {
        address: '', email: '', inn: '', juridicalAddress: '', longName: '', name: '', ogrn: '', phone: ''
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(juridicalPersons.actions.create(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.ORGANIZATION_JURIDICAL_PERSONS)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksOrganization}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Создание Юр. лица'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Короткое название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Полное название"
                                        name="longName"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Фактический адрес"
                                        name="address"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Юридический адрес"
                                        name="juridicalAddress"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Email"
                                        name="email"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Телефон"
                                        name="phone"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>

    )
}