import { TApiToken } from '../../../services/auth/types'
import jwtDecode from 'jwt-decode'

interface TJWTPayload {
    email: string,
    exp: number,
    iat: number,
    id: string,
    name: string,
    roles: string[]
}

export interface TParsedResponse extends TJWTPayload {
    token: string,
}

export function parseToken(response: TApiToken): TParsedResponse {
    const data = jwtDecode<TJWTPayload>(response.token)
    return {
        ...data,
        token: response.token,
    }
}