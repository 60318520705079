import React from 'react'
import { fieldToTextField, TextFieldProps } from 'formik-material-ui'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'


export interface FormikAutocompleteProps extends TextFieldProps {
    nullable: boolean,
    nullableText: string,
    items: Array<{value: string, label: string}>
}

export default function FormikAutocomplete({
    items,
    ...props
}: FormikAutocompleteProps): JSX.Element {

    const {
        form: { setFieldValue }
    } = props
    const { ...field } = fieldToTextField(props)
    const { name, value, label } = field

    return (
        // eslint-disable-next-line
        <Autocomplete<{value: string, label: string} | any, false, false, false>
            value={items.find((item) => item.value === value)}
            options={items}
            getOptionLabel={(option) => option.label}
            // eslint-disable-next-line
            onChange={(event: any, newValue: {value: string, label: string}) => {
                console.log(newValue)
                setFieldValue(name as string, newValue?.value ?? '')
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="standard" />
            )}
        />
    )
}