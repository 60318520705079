import { makeStyles } from '@material-ui/core'
import { getLabelTransform, theme } from '../../theme'

export const useStyles = makeStyles(() => ({
    field: {
        marginTop: theme.spacing(3),
        '& .MuiInputAdornment-root': {
            height: 'auto'
        }
    },
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    withLabel2Lines: {
        marginTop:  theme.spacing(5),
    },
    label2Lines: {
        '&.shrink': {
            transform: getLabelTransform(-35),
        },
    },
}))