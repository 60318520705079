import React from 'react'
import { useStyles } from './content.style'
import { CircularProgress, Paper, Typography } from '@material-ui/core'

interface ContentProps  {
    children: React.ReactNode,
    title: string,
    loading: boolean,
    notFoundContent: boolean
}

export function Content(props: ContentProps): JSX.Element {
    const classes = useStyles()

    return (
        <div className={classes.content}>
            { props.title ? <Typography variant="h5" className={classes.contentTitle}>{props.title}</Typography> : null }
            {props.loading ?
                <CircularProgress size={30}/>
                : (!props.notFoundContent ?
                    <Paper className={classes.contentPaper} elevation={4}>{props.children}</Paper>
                    : <Typography>{'Not found'}</Typography>)
            }
        </div>
    )
}