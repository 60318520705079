import React, { ReactElement, useEffect, useState } from 'react'
import { Box, Link } from '@material-ui/core'
import { SideBar } from '../../../components/side-bar'
import { navLinksProduct } from '../../../services'
import {
    prices,
} from '../../../store/slices'
import { useAppDispatch } from '../../../store'
import { useSelector } from 'react-redux'
import { IPrice } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'
import { getIdFromIRI } from '../../../services/_common/utils'
import { DataTable } from '../../../components/dataTable'
import { GridCellParams, GridRowModel } from '@material-ui/data-grid'
import { PriceEditModal } from './edit'

export function PricesPage(): JSX.Element {

    const dispatch = useAppDispatch()
    const fetchStatus = useSelector(prices.selectors.fetchStatus)
    const totalItems = useSelector(prices.selectors.totalItems)

    const [currentPriceId, setCurrentPriceId] = useState('')
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        dispatch(prices.actions.fetch({pagination: false}))
    }, [refresh])

    const makeModelLink = (params:GridCellParams) : ReactElement => {
        return (
            params.getValue('modelName') ? <Link href={`/product/models/edit/${params.getValue('model')}`}>{ params.getValue('modelName') }</Link> : <></>
        )
    }

    const editOption = (row: GridRowModel) => {
        setCurrentPriceId(row.id as string)
    }

    const handleModalEditClose = (ok: boolean) => {
        setCurrentPriceId('')
        ok && setRefresh(!refresh)
    }

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksProduct}>
                Фильтры будут
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    fullWidth*/}
                {/*    component={Link}*/}
                {/*    to={ROUTES.PRODUCT_OPTIONS_CREATE}*/}
                {/*>*/}
                {/*    Создать*/}
                {/*</Button>*/}
            </SideBar>
            <DataTable<IPrice, FetchCreatedAtParams & FetchCollectionParams>
                title={'Цены'}
                urlPathEdit={'/product/prices/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={prices.actions.fetch}
                selector={prices.selectors.selectAll}
                deleteAction={prices.actions.remove}
                editAction={editOption}
                mappingFunction={(price) => ({
                    id: getIdFromIRI(price.id),
                    type: price?.type?.text,
                    actionValue: price?.actionValue || '',
                    actionPrice: price?.actionPrice || '',
                    dateStart: price?.dateStart || '',
                    dateEnd: price?.dateEnd || '',
                    modelName: price?.modelName || '',
                    model: getIdFromIRI(price?.model || ''),
                    product: price?.product || '',
                    price: price.price ? `${new Intl.NumberFormat('ru-RU').format(price.price.amount / 100)} ${price.price.currency}` : '',
                    costPrice: price.costPrice ? `${new Intl.NumberFormat('ru-RU').format(price.costPrice.amount / 100)} ${price.costPrice.currency}` : '',
                })}
                loading={fetchStatus.isFetching}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true },
                    { field: 'type', headerName: 'Тип', flex: 1, sortable: false },
                    { field: 'price', headerName: 'Цена', flex: 1, sortable: false },
                    { field: 'costPrice', headerName: 'Цена покупка', flex: 1, sortable: false },
                    { field: 'actionValue', headerName: 'Акция значение', flex: 1, sortable: false },
                    { field: 'actionPrice', headerName: 'Акция цена', flex: 1, sortable: false },
                    { field: 'dateStart', headerName: 'Акция старт', flex: 1, sortable: false },
                    { field: 'dateEnd', headerName: 'Акция конец', flex: 1, sortable: false },
                    //{ field: 'modelName', headerName: 'Модель', flex: 1, sortable: false },
                    { field: 'modelName', headerName: 'Модель', flex: 1, sortable: false, renderCell: makeModelLink },
                    { field: 'product', headerName: 'Продукт', flex: 1, sortable: false },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
            { !!currentPriceId && <PriceEditModal id={currentPriceId} onClose={handleModalEditClose} /> }
        </Box>
    )
}