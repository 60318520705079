import React from 'react'
import {
    Divider,
    List, ListItem, ListItemText,
    Paper,
} from '@material-ui/core'
import { useStyles } from './side-bar.style'
import { Link } from 'react-router-dom'
import { NavLink } from '../../services/_common'

interface SideBarProps {
    children: React.ReactNode,
    navLinks: Array<NavLink>
}

export function SideBar(props: SideBarProps): JSX.Element {
    const classes = useStyles()

    return (
        <div className={classes.sideBar}>
            <Paper elevation={4} className={classes.sideBarPaper}>
                {props.children}
                <Divider className={classes.sideBarDivider}/>
                <List component="nav">
                    {props.navLinks.map((navLink: NavLink) => <ListItem
                        key={navLink.path}
                        button
                        component={Link}
                        to={navLink.path}
                    >
                        <ListItemText primary={navLink.text}/>
                    </ListItem>)}
                </List>
            </Paper>
        </div>
    )
}