import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
} from '@material-ui/core'
import { juridicalPersons } from '../../../../store/slices'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksOrganization, validation } from '../../../../services'
import { Field, Form, Formik } from 'formik'
import { ProviderUpdateProps } from '../../../../store/types'
import { TextField } from 'formik-material-ui'
import { providers } from '../../../../store/slices/provider'

interface EditPageInterface {
    match: {
        params: {
            id: string
        }
    }
}

export function ProviderEditPage(props: EditPageInterface): JSX.Element {
    useEffect(() => {
        dispatch(juridicalPersons.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()
    const entity = useAppSelector((state) => providers.selectors.selectById(state, props.match.params.id))
    const initialValues: ProviderUpdateProps = entity ? entity : {
        id: '',
        iri: '',
        name: '',
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(providers.actions.update(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.goBack()
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksOrganization}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Редактирование поставщика'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Короткое название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}