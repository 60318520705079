import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
    section: {
        marginTop: 30
    },
    subHeading: {
        marginTop: 15
    },
    subField: {
        marginTop: 5
    },
    grow: {
        flexGrow: 1
    }
}))