import * as Yup from 'yup'

const required = (value: string|null): Promise<string> => {
    const schema = Yup.object().shape({
        value: Yup.string()
            .nullable()
            .required()
    })

    return schema.validate({ value })
        .then(
            () => {return null},
            (err) => {return err.errors[0]},
        )
}

const isPositive = (value: number|null): string|null => {
    return value !== null && value <= 0 ? 'The value must be a positive number' : null
}

export const validation = {
    required,
    isPositive
}
