import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchSpendingProps, ISpending, TSpendingsState } from '../../types'
import { moneyFlowService } from '../../../services'
import { parseHttpError } from '../../utils'
import { selectors } from './selectors'
import { spendingsAdapter } from './adapter'
import { httpService, Money } from '../../../services/_common'
import { TApiSpending } from '../../../services/moneyFlow/types'
import { parseSpendings } from './utils'

const name = 'spendings'

const initialState: TSpendingsState = {
    ...spendingsAdapter.getInitialState(),
    isFetching: false,
    totalItems: 0,
}


const fetch = createAsyncThunk(
    `${name}/fetch`,
    async (params: FetchSpendingProps | undefined) => {
        return httpService.fetchCollection<TApiSpending, ISpending, FetchSpendingProps>({
            url: '/deals',
            params,
            parseFunction: parseSpendings
        })
    },
    {
        serializeError: parseHttpError
    }
)

const remove = createAsyncThunk(
    `${name}/remove`,
    async (id: string) => {
        return await moneyFlowService.removeSpending(id)
    },
    {
        serializeError: parseHttpError
    }
)

export interface CreateSpendingProps {
    deal: string|null,
    businessUnit: string|null,
    paymentType: string|null,
    spendingType: string|null,
    amount: Money,
    debtOnBusinessUnit: string|null,
    comment: string|null,
    actualDate: string|undefined
}

const create = createAsyncThunk(
    `${name}/create`,
    async (spending: CreateSpendingProps) => {
        return await moneyFlowService.createSpending(spending)
    },
    {
        serializeError: parseHttpError
    }
)

export interface CreateSpendingsProps {
    spendings: Array<CreateSpendingProps>
}

const createMany = createAsyncThunk(
    `${name}/create-many`,
    async (spendings: CreateSpendingsProps) => {
        return await moneyFlowService.createSpendings(spendings)
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.totalItems = action.payload.totalItems
                spendingsAdapter.setAll(state, action.payload.entities)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const spendings = {
    name: slice.name,
    actions: {
        fetch,
        create,
        createMany,
        remove,
    },
    reducer: slice.reducer,
    selectors,
}