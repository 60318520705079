import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { sale, users } from '../../store/slices'
import {
    Button,
    CircularProgress,
    Paper, Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { useAppDispatch } from '../../store'

export function SalePage(): JSX.Element {
    const usersList = useSelector(users.selectors.selectAll)
    const fetchStatus = useSelector(users.selectors.fetchStatus)
    const testData = useSelector(sale.selectors.dataWithUser)
    const testStatus = useSelector(sale.selectors.fetchStatus)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(users.actions.fetch())
        dispatch(sale.actions.fetch())
    }, [])

    const handleClick = useCallback(() => {
        dispatch(users.actions.fetch())
    }, [])

    const usersTable = useMemo(() => (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersList.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ), [usersList])

    return (
        <div>
            <Typography>SALE</Typography>
            <Typography>TEST DATA</Typography>
            {!testStatus.isFetching && testData && <div>{testData.result} / {JSON.stringify(testData.user, null, 2)}</div>}
            {testStatus.error && <Typography color="error">Error: {testStatus.error.message}</Typography>}
            <Typography>Test users</Typography>
            <Button onClick={handleClick} disabled={fetchStatus.isFetching}>Refresh</Button>
            {fetchStatus.error && <Typography color="error">Error loading users: {fetchStatus.error.message}</Typography>}
            {fetchStatus.isFetching ? <CircularProgress size={20}/> : usersTable}
        </div>
    )
}
