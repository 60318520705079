import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
    auth,
    sale,
    users,
    bills,
    billTypes,
    businessUnits,
    spendingTypes,
    paymentTypes,
    deals,
    spendings,
    billMoneys,
    transactions,
    transactionStatuses,
    expenditureTypes,
    juridicalPersons,
    subSpendingTypes,
    attributes,
    attributeGroups,
    options,
    storages,
    categories,
    models,
    modelOptionTypes,
    priceTypes,
    prices, modelPresets,
} from './slices'
import { TRootState } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { httpService } from '../services/_common'
import { providers } from './slices/provider'

const rootReducer = combineReducers<TRootState>({
    auth: auth.reducer,
    users: users.reducer,
    sale: sale.reducer,
    bills: bills.reducer,
    billTypes: billTypes.reducer,
    businessUnits: businessUnits.reducer,
    spendingTypes: spendingTypes.reducer,
    paymentTypes: paymentTypes.reducer,
    deals: deals.reducer,
    spendings: spendings.reducer,
    billMoneys: billMoneys.reducer,
    transactions: transactions.reducer,
    transactionStatuses: transactionStatuses.reducer,
    expenditureTypes: expenditureTypes.reducer,
    juridicalPersons: juridicalPersons.reducer,
    subSpendingTypes: subSpendingTypes.reducer,
    providers: providers.reducer,
    attributes: attributes.reducer,
    attributeGroups: attributeGroups.reducer,
    options: options.reducer,
    storages: storages.reducer,
    categories: categories.reducer,
    models: models.reducer,
    modelOptionTypes: modelOptionTypes.reducer,
    priceTypes: priceTypes.reducer,
    prices: prices.reducer,
    modelPresets: modelPresets.reducer
})

const persistedReducer = persistReducer<TRootState>({
    key: 'mgWeb',
    version: 1,
    storage,
    blacklist: [],
    whitelist: ['auth']
}, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    }),
    devTools: process.env.NODE_ENV !== 'production',
})

httpService.setStore(store)

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export function useAppSelector <TState = TRootState, TSelected = unknown>(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
    return useSelector(selector, equalityFn)
}
