import React from 'react'
import { useStyles } from './data-table.style'
import { Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { DataTableProps } from './types'
import { Table } from './Table'
import { useAppDispatch } from '../../store'
import { FetchCollectionParams } from '../../services/_common'
import qs from 'query-string-object'
import { useLocation } from 'react-router-dom'


export function DataTable<Entity, FetchProps extends FetchCollectionParams>({
    title,
    columns,
    fetchAction,
    selector,
    mappingFunction,
    exportMappingFunction,
    headerExportColumns,
    fetchProps,
    loading,
    filters,
    deleteAction,
    urlPathEdit,
    editAction,
    exportFunction,
    ...props
}: DataTableProps<Entity, FetchProps>): JSX.Element {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const fetchPropsFromQueryString = qs.parse(location.search.replace('?', ''))


    Object.assign(fetchProps, fetchPropsFromQueryString)


    return (
        <div className={classes.wrapper}>
            {title ? <Typography variant="h5" className={classes.title}>{title}</Typography> : null}
            <Formik<FetchProps>
                initialValues={fetchProps}
                onSubmit={(values, { setSubmitting }) => {
                    dispatch(fetchAction(values))
                        .then(() => {setSubmitting(true)})
                }}
            >
                {() => (
                    <Form style={{height: '100%'}}>
                        <div className={classes.tableContainerWrapper}>
                            <div className={classes.tableContainer}>
                                <div className={classes.tableWrapper}>
                                    <Table<Entity, FetchProps>
                                        rows={[]} // Я там унаследовался и этот проп обязателен
                                        columns={columns}
                                        deleteActions={deleteAction}
                                        urlPathEdit={urlPathEdit}
                                        editAction={editAction}
                                        mappingFunction={mappingFunction}
                                        headerExportColumns={headerExportColumns}
                                        exportMappingFunction={exportMappingFunction}
                                        selector={selector}
                                        dataFunction={fetchAction}
                                        loading={loading}
                                        exportFunction={exportFunction}
                                        filters={filters}
                                        {...props}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}