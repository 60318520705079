import { IconButtonProps } from '@material-ui/core/IconButton/IconButton'
import { useFormikContext } from 'formik'
import { IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import React from 'react'

export function RefreshButton(props: IconButtonProps): JSX.Element {
    const {
        submitForm,
    } = useFormikContext()

    return (<IconButton
        {...props}
        onClick={submitForm}
    >
        <RefreshIcon/>
    </IconButton>)
}