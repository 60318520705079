import { ICollection } from '../../../services/_common'
import { IModel, ModelAttribute, ModelOption, ModelOptionValue } from '../../types'
import {
    TApiModel,
    TApiModelAttribute,
    TApiModelOption,
    TApiModelOptionValue,
    TApiModels,
} from '../../../services/product/types'
import { categoryTransformer } from '../categories/utils'
import { fileTransformer } from '../../utils'
import { enumTransformer } from '../../../services/_common/utils'
import { priceTransformer } from '../prices/utils'


export function parseModels(response: TApiModels): ICollection<IModel> {
    return {
        entities: response.entities.map(modelTransformer),
        totalItems: response.totalItems
    }
}

const modelOptionValueTransformer = (apiObject: TApiModelOptionValue) : ModelOptionValue => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    value: apiObject.value,
    image: apiObject.image ? fileTransformer(apiObject.image) : null
})

export const modelOptionTransformer = (apiObject: TApiModelOption) : ModelOption => ({
    type: enumTransformer(apiObject.type),
    option: apiObject.option,
    values: apiObject.values.map(modelOptionValueTransformer),
    priority: apiObject?.priority
})

export const modelAttributeTransformer = (apiObject: TApiModelAttribute) : ModelAttribute => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    attribute: apiObject.attribute,
    value: apiObject.value,
    priority: apiObject.priority,
})

export const modelTransformer = (apiObject: TApiModel) : IModel => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    description: apiObject.description,
    mainCategory: apiObject.mainCategory ? categoryTransformer(apiObject.mainCategory) : null,
    categories: apiObject.categories.map(categoryTransformer),
    mainImage: apiObject.mainImage ? fileTransformer(apiObject.mainImage) : null,
    images: apiObject.images.map(fileTransformer),
    modelOptions: apiObject.modelOptions.map( modelOptionTransformer ),
    modelAttributes: apiObject.modelAttributes.map( modelAttributeTransformer ),
    prices: apiObject.prices.map( priceTransformer )
})