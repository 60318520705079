import React, { useEffect, useMemo } from 'react'
import {
    Box,
    Button,
    Grid, IconButton, Typography,
} from '@material-ui/core'
import { attributes, categories, modelOptionTypes, models, options } from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik'
import { TextField, TextFieldProps } from 'formik-material-ui'
import { FileField } from '../../../../components/fileField'
import { IOption, ModelAttribute } from '../../../../store/types'
import { useStyles } from '../index.style'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useSelector } from 'react-redux'
import { SelectField } from '../../../../components/selectField'
import { navLinksProduct, validation } from '../../../../services'
import { SelectFieldMultiple } from '../../../../components/selectFieldMultiple'
import { NumericField } from '../../../../components/numericField'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { ROUTES } from '../../../../constants'
import { emptyProductPrice, ProductPriceField } from '../../../../components/productPriceField'

interface SelectedOptionValuesFieldProps extends TextFieldProps {
    options: IOption[],
    index: number
}

export const SelectedOptionValuesField = (props: SelectedOptionValuesFieldProps) : JSX.Element => {
    const { options, index } = props

    const {
        values: { modelOptions },
    } = useFormikContext()

    const filteredValues = useMemo(() => {
        const selectedOption = modelOptions[index]?.option
        if (selectedOption) {
            const optionValues = options.find((it) => it.iri === selectedOption)?.values
            if (optionValues) {
                return optionValues.map((it) => ({ value: it.iri, label: it.value }))
            }
        }
        return []
    }, [modelOptions[index]?.option, options, index])

    return (
        <SelectFieldMultiple
            {...props}
            items={filteredValues}
        />
    )
}

export function ModelCreatePage(): JSX.Element {
    useEffect(() => {
        dispatch(models.actions.fetch())
        dispatch(categories.actions.fetch())
        dispatch(attributes.actions.fetch())
        dispatch(options.actions.fetch())
        dispatch(modelOptionTypes.actions.fetch())
    }, [])

    const classes = useStyles()

    const dispatch = useAppDispatch()
    const history = useHistory()

    const categoriesList = useSelector(categories.selectors.selectAll)
    const attributesList = useSelector(attributes.selectors.selectAll)
    const optionsList = useSelector(options.selectors.selectAll)
    const modelOptionTypesList = useSelector(modelOptionTypes.selectors.selectAll)

    const categoryItems = useMemo(() => categoriesList.map((item) => ({value: item.iri, label: item.name})), [categoriesList])
    const attributesItems = useMemo(() => attributesList.map((item) => ({value: item.iri, label: item.name})), [attributesList])
    const optionsItems = useMemo(() => optionsList.map((item) => ({value: item.iri, label: item.name})), [optionsList])
    const modelOptionTypeItems = useMemo(() => modelOptionTypesList.map((item) => ({value: item.iri, label: item.text})), [modelOptionTypesList])

    // const emptyModelOptionValue = {
    //     value: '',
    //     image: null
    // }

    const emptyModelOption = {
        type: '',
        option: '',
        values: [],
        priority: 100
    }

    const emptyModelAttribute = {
        attribute: null,
        value: '',
        priority: 100,
    }

    const initialModel = {
        name: '',
        description: '',
        mainCategory: null,
        categories: [],
        mainImage: null,
        images: [],
        modelOptions: [],
        modelAttributes: [],
        prices: []
    }

    return (
        <Formik
            initialValues={initialModel}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(models.actions.create(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.PRODUCT_MODELS)
                    })
                setSubmitting(false)
            }}

        >
            {({ submitForm, values: model }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksProduct}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Создание модели'}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        required
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        component={TextField}
                                        label="Описание"
                                        name="description"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label='Основная категория'
                                        name='mainCategory'
                                        component={SelectField}
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={categoryItems}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label='Дополнительные категории'
                                        name='categories'
                                        component={SelectFieldMultiple}
                                        fullWidth
                                        items={categoryItems}
                                    />
                                </Grid>
                                {/*<Grid item xs={3}>*/}
                                {/*    <ModelPreset/>*/}
                                {/*</Grid>*/}
                            </Grid>

                            <Grid container spacing={2} className={classes.section}>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Доступные атрибуты</Typography>
                                    { !model.modelAttributes.length && <p>Пока пусто</p> }
                                    <FieldArray
                                        name="modelAttributes"
                                        render={(arrayHelpers) => (
                                            <>
                                                {model.modelAttributes.map((value:ModelAttribute, index) => {
                                                    const selectedAttribute = value.attribute ? attributesList.find((attribute) => attribute.iri === value.attribute) : null
                                                    const selectedAttributeValues = selectedAttribute ? selectedAttribute.values?.map((value) => ({value: value, label: value})) : null
                                                    return (
                                                        <Grid key={index} container spacing={2}>
                                                            <Grid item xs={4}>
                                                                <Field
                                                                    label="Атрибут"
                                                                    name={`modelAttributes[${index}].attribute`}
                                                                    component={SelectField}
                                                                    fullWidth
                                                                    required
                                                                    validate={validation.required}
                                                                    nullableText={'Не выбрано'}
                                                                    items={attributesItems}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                {selectedAttribute && selectedAttributeValues ?
                                                                    <Field
                                                                        component={SelectField}
                                                                        label="Значение"
                                                                        name={`modelAttributes[${index}].value`}
                                                                        fullWidth
                                                                        required
                                                                        validate={validation.required}
                                                                        items={selectedAttributeValues}
                                                                    />
                                                                    :
                                                                    <Field
                                                                        component={TextField}
                                                                        label="Значение"
                                                                        name={`modelAttributes[${index}].value`}
                                                                        fullWidth
                                                                        required
                                                                        validate={validation.required}
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Field
                                                                    component={NumericField}
                                                                    label="Приоритет"
                                                                    name={`modelAttributes[${index}].priority`}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <HighlightOffIcon/>
                                                                </IconButton>

                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                                )}
                                                <Button
                                                    fullWidth={true}
                                                    style={{marginTop: 10}}
                                                    onClick={() => arrayHelpers.push({...emptyModelAttribute})}
                                                >
                                                    Добавить атрибут
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Доступные опции</Typography>
                                    { !model.modelOptions.length && <p>Пока пусто</p> }
                                    <FieldArray
                                        name="modelOptions"
                                        render={(arrayHelpers) => (
                                            <>
                                                {model.modelOptions.map((modelOption, index) => (
                                                    <Grid key={index} container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                label='Опция'
                                                                name={`modelOptions[${index}].option`}
                                                                component={SelectField}
                                                                fullWidth
                                                                required
                                                                validate={validation.required}
                                                                nullableText={'Не выбрано'}
                                                                items={optionsItems}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                label='Тип'
                                                                name={`modelOptions[${index}].type`}
                                                                component={SelectField}
                                                                fullWidth
                                                                required
                                                                validate={validation.required}
                                                                nullableText={'Не выбрано'}
                                                                items={modelOptionTypeItems}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                label='Значения'
                                                                name={`modelOptions[${index}].values`}
                                                                component={SelectedOptionValuesField}
                                                                fullWidth
                                                                required
                                                                options={optionsList}
                                                                index={index}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Field
                                                                component={NumericField}
                                                                label="Приоритет"
                                                                name={`modelOptions[${index}].priority`}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <HighlightOffIcon/>
                                                            </IconButton>

                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button
                                                    fullWidth={true}
                                                    style={{marginTop: 10}}
                                                    onClick={() => arrayHelpers.push({...emptyModelOption})}
                                                >
                                                    Добавить опцию
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container spacing={2} className={classes.section}>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Цены</Typography>
                                    { !model.prices.length && <p>Пока пусто</p> }
                                    <FieldArray
                                        name="prices"
                                        render={(arrayHelpers) => (
                                            <>
                                                { model.prices.map( (price, index) => (
                                                    <Grid key={index} container spacing={2} alignItems="center">
                                                        <Grid item xs={10}>
                                                            <ProductPriceField
                                                                prefix={`prices.${index}.`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <HighlightOffIcon/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )  ) }
                                                <Button
                                                    fullWidth={true}
                                                    style={{marginTop: 10}}
                                                    onClick={() => arrayHelpers.push({...emptyProductPrice})}
                                                >
                                                    Добавить цену
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Изображения</Typography>
                                    <Field
                                        component={FileField}
                                        label="Основное изображение"
                                        name="mainImage"
                                        isImage
                                        fullWidth
                                    />
                                    <Typography variant="subtitle2" className={classes.subHeading}>Дополнительные</Typography>
                                    { !model.images.length && <p>Пока пусто</p> }
                                    <FieldArray
                                        name="images"
                                        render={(arrayHelpers) => (
                                            <>
                                                {model.images.map((value, index) => (
                                                    <Grid key={index} container spacing={2}>
                                                        <Grid item className={classes.grow}>
                                                            <Field
                                                                name={`images[${index}]`}
                                                                id={`${index}image`}
                                                                isImage
                                                                component={FileField}
                                                                label="Картинка"
                                                                fullWidth
                                                                key={`${index}image`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <HighlightOffIcon/>
                                                            </IconButton>

                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button
                                                    fullWidth={true}
                                                    style={{marginTop: 10}}
                                                    onClick={() => arrayHelpers.push(null)}
                                                >
                                                    Добавить картинку
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>


                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}