import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid, IconButton, Typography,
} from '@material-ui/core'
import { attributeGroups, attributes } from '../../../../store/slices'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { validation } from '../../../../services'
import { Field, FieldArray, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { SelectField } from '../../../../components/selectField'
import { useSelector } from 'react-redux'
import { navLinksProduct } from '../../../../services'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

interface AttributeEditPageProps {
    match: {
        params: {
            id: string
        }
    }
}

export function AttributeEditPage(props: AttributeEditPageProps): JSX.Element {
    useEffect(() => {
        dispatch(attributeGroups.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()

    const attribute = useAppSelector((state) => attributes.selectors.selectById(state, props.match.params.id))
    const attributeGroupsList = useSelector(attributeGroups.selectors.selectAll)

    const initialValues = {
        id: attribute?.iri || '',
        name: attribute?.name || '',
        description: attribute?.description || '',
        group: attribute?.group?.iri || null,
        values: attribute?.values || [],
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(attributes.actions.update({iri: values.id, data: values}))
                    .then(unwrapResult)
                    .then(() => {
                        history.goBack()
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm, values: attribute }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksProduct}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Редактирование атрибута'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Описание"
                                        name="description"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={SelectField}
                                        label="Группа"
                                        name="group"
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={attributeGroupsList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h6">Возможные значения</Typography>
                                    { !attribute.values.length && <p>Возможно любое значение</p> }
                                    <FieldArray
                                        name="values"
                                        render={(arrayHelpers) => (
                                            <>
                                                {attribute.values.map((value, index) => (
                                                    <Grid key={index} container spacing={2}>
                                                        <Grid item xs={10}>
                                                            <Field
                                                                component={TextField}
                                                                label="Значение"
                                                                name={`values[${index}]`}
                                                                fullWidth
                                                                required
                                                                validate={validation.required}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            >
                                                                <HighlightOffIcon/>
                                                            </IconButton>

                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button
                                                    fullWidth={true}
                                                    style={{marginTop: 10}}
                                                    onClick={() => arrayHelpers.push('')}
                                                >
                                                    Добавить значение
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}