import React, { useEffect } from 'react'
import { Box, Button, Divider, Grid } from '@material-ui/core'
import {
    businessUnits,
    CreateSpendingsProps, juridicalPersons,
    paymentTypes,
    spendings,
    spendingTypes, subSpendingTypes,
} from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { SideBar } from '../../../../components/side-bar'
import { navLinksMoneyFlow, validation } from '../../../../services'
import { Content } from '../../../../components/content'
import { useSelector } from 'react-redux'
import { Form, Formik, Field, FieldArray, useFormikContext, useField } from 'formik'
import { TextField } from 'formik-material-ui'
import { ROUTES } from '../../../../constants'
import { unwrapResult } from '@reduxjs/toolkit'
import { SelectField } from '../../../../components/selectField'
import { DatePicker } from 'formik-material-ui-pickers'
import moment, { Moment } from 'moment'
import { NumericField } from '../../../../components/numericField'
import { FieldAttributes } from 'formik/dist/Field'
import { getIdFromIRI } from '../../../../services/_common/utils'
import { providers } from '../../../../store/slices/provider'
import FormikAutocomplete from '../../../../components/formikAutocomplete'

interface IInitialValues {
    spendings: Array<{
        deal: null,
        businessUnit: string|null,
        paymentType: string|null,
        spendingType: string|null,
        subSpendingType: string|null,
        juridicalPerson: string|null,
        provider: string|null,
        amount: number,
        debtOnBusinessUnit: string|null,
        comment: string|null,
        actualDate: Moment
    }>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyField = ({index, ...props }: FieldAttributes<any>) => {
    const {
        values,
        setFieldValue,
    } = useFormikContext<IInitialValues>()
    const [field] = useField(props)
    const { spendingType } = values.spendings[index]
    const subSpendingTypesList = useSelector(subSpendingTypes.selectors.selectBySpendingTypeId(getIdFromIRI(spendingType??'')))

    React.useEffect(() => {
        if(subSpendingTypesList.length > 0) {
            setFieldValue(props.name, subSpendingTypesList[0].iri)
        }
    }, [spendingType])

    return (
        <Field {...props} {...field} items={subSpendingTypesList.map((item) => ({value: item.iri, label: item.name}))} />
    )
}

export function SpendingCreatePage(): JSX.Element {
    const dispatch = useAppDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(businessUnits.actions.fetch({pagination: false}))
        dispatch(paymentTypes.actions.fetch())
        dispatch(spendingTypes.actions.fetch({pagination: false}))
        dispatch(subSpendingTypes.actions.fetch({pagination: false}))
        dispatch(juridicalPersons.actions.fetch({pagination: false}))
        dispatch(providers.actions.fetch({pagination: false}))
    }, [])

    const paymentTypesList = useSelector(paymentTypes.selectors.selectAll)
    const businessUnitsList = useSelector(businessUnits.selectors.selectAll)
    const spendingTypesList = useSelector(spendingTypes.selectors.selectActive)
    const juridicalPersonsList = useSelector(juridicalPersons.selectors.selectAll)
    const providersList = useSelector(providers.selectors.selectAll)

    const emptyValues = {
        deal: null,
        businessUnit: null,
        paymentType: null,
        spendingType: null,
        subSpendingType: null,
        juridicalPerson: null,
        provider: null,
        amount: 0,
        debtOnBusinessUnit: null,
        comment: '',
        actualDate: moment(),
    }

    const initialValues: IInitialValues = {
        spendings: [
            emptyValues
        ],
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {

                const data: CreateSpendingsProps = {
                    spendings: values.spendings.map((item) => ({
                        ...item,
                        deal: null,
                        actualDate: item.actualDate.format('YYYY-MM-DD'),
                        amount: {
                            amount: Math.round(item.amount*100),
                            currency: 'RUB'
                        }
                    }))
                }

                dispatch(spendings.actions.createMany(data))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.MONEY_FLOW_SPENDINGS)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm, values }) => (
                <Box display="flex">
                    <SideBar navLinks={navLinksMoneyFlow}>
                        <Button
                            fullWidth={true}
                            variant={'contained'}
                            onClick={submitForm}
                        >
                            Сохранить
                        </Button>
                    </SideBar>
                    <Content loading={false} notFoundContent={false} title={'Создание расхода'}>
                        <Form>
                            <FieldArray
                                name="spendings"
                                render={(arrayHelpers) => (
                                    <>
                                        {values.spendings.map((spending, index) => (
                                            <Grid key={index} container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Field
                                                        label={'Бизнес юнит'}
                                                        name={`spendings[${index}].businessUnit`}
                                                        component={SelectField}
                                                        validate={validation.required}
                                                        required
                                                        fullWidth
                                                        nullable
                                                        nullableText={'Не выбрано'}
                                                        items={businessUnitsList.map((item) => ({value: item.iri, label: item.name}))}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        label={'Статья расхода'}
                                                        name={`spendings[${index}].spendingType`}
                                                        component={SelectField}
                                                        validate={validation.required}
                                                        required
                                                        fullWidth
                                                        nullable
                                                        nullableText={'Не выбрано'}
                                                        items={spendingTypesList.map((item) => ({value: item.iri, label: item.name}))}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <MyField
                                                        index={index}
                                                        label={'Подстатья расхода'}
                                                        name={`spendings[${index}].subSpendingType`}
                                                        component={SelectField}
                                                        fullWidth
                                                        nullable
                                                        nullableText={'Не выбрано'}
                                                        items={spendingTypesList.map((item) => ({value: item.iri, label: item.name}))}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        label={'Тип оплаты'}
                                                        name={`spendings[${index}].paymentType`}
                                                        component={SelectField}
                                                        validate={validation.required}
                                                        required
                                                        fullWidth
                                                        nullable
                                                        nullableText={'Не выбрано'}
                                                        items={paymentTypesList.map((item) => ({value: item.iri, label: item.text}))}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name={`spendings[${index}].amount`}
                                                        component={NumericField}
                                                        validate={validation.isPositive}
                                                        required
                                                        type="number"
                                                        label="Сумма"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        label={'Бизнес юнит за который будет внесена оплата'}
                                                        name={`spendings[${index}].debtOnBusinessUnit`}
                                                        component={SelectField}
                                                        fullWidth
                                                        nullable
                                                        nullableText={'Не выбрано'}
                                                        items={businessUnitsList.map((item) => ({value: item.iri, label: item.name}))}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name={`spendings[${index}].comment`}
                                                        component={TextField}
                                                        label="Комментарий"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        label="Фактическая дата списания"
                                                        name={`spendings[${index}].actualDate`}
                                                        component={DatePicker}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        label={'Поставщик'}
                                                        name={`spendings[${index}].provider`}
                                                        component={FormikAutocomplete}
                                                        validate={validation.required}
                                                        required
                                                        fullWidth
                                                        nullable
                                                        nullableText={'Не выбрано'}
                                                        items={providersList.map((item) => ({value: item.iri, label: item.name}))}
                                                    />
                                                </Grid>
                                                { spendingTypesList.find((item) => item.iri === values.spendings[index].spendingType)?.requiredJuridicalPerson
                                                    ? <Grid item xs={3}>
                                                        <Field
                                                            label={'Юр лицо'}
                                                            name={`spendings[${index}].juridicalPerson`}
                                                            component={SelectField}
                                                            validate={validation.required}
                                                            required
                                                            fullWidth
                                                            nullable
                                                            nullableText={'Не выбрано'}
                                                            items={juridicalPersonsList.map((item) => ({value: item.iri, label: item.name}))}
                                                        />
                                                    </Grid>
                                                    : null
                                                }
                                                <Grid item xs={3}>
                                                    <Button
                                                        fullWidth={true}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Убрать
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{margin: 15}} />
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Button
                                            fullWidth={true}
                                            onClick={() => arrayHelpers.push(emptyValues)}
                                        >
                                            Добавить
                                        </Button>
                                    </>
                                )}
                            />
                        </Form>
                    </Content>
                </Box>
            )}
        </Formik>
    )
}
