import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { LS_JWT_TOKEN, ROUTES } from '../../constants'
import { auth } from '../../store/slices'
import { EnhancedStore } from '@reduxjs/toolkit'
import { TRootState } from '../../store/types'
import {
    FetchCollectionParams,
    FetchCreatedAtParams,
    HydraCollectionResponse,
    ICollection,
    TApiCollection, TApiFile,
} from './types'
import qs from 'query-string-object'
import { mapHydraCollection } from './utils'

export * from './types'

axios.defaults.withCredentials = true

export interface IParseFunction<TApiEntity, IEntity> {
    (apiCollection: TApiCollection<TApiEntity>): ICollection<IEntity>
}

export const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:6262'

class HttpService {
    private store: EnhancedStore<TRootState> | null = null

    setStore(store: EnhancedStore<TRootState>) {
        this.store = store
    }

    async request<T> (config: AxiosRequestConfig, isWithoutToken = false): Promise<AxiosResponse<T>> {
        config.baseURL = baseURL
        if (!isWithoutToken) {
            const token = window.localStorage.getItem(LS_JWT_TOKEN)
            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                }
            }
        }
        try {
            return await axios.request<T>(config)
        } catch (e) {
            if (e.response && e.response.status === 401) {
                // TODO: Как-то это всё не правильно, надо подумать как это сделать лучше
                if (this.store) {
                    this.store.dispatch(auth.actions.signOut())
                }
                window.location.href = ROUTES.SIGN_IN
            }
            throw e
        }
    }

    async fetchCollection<TApiEntity, IEntity, FetchParams>({ url, params, parseFunction }: {
        url: string,
        params: FetchParams & FetchCreatedAtParams & FetchCollectionParams | undefined
        parseFunction: IParseFunction<TApiEntity, IEntity>
    }): Promise<ICollection<IEntity>> {
        const response = await this.request<HydraCollectionResponse<TApiEntity>>({
            method: 'get',
            url,
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params)
            }
        })

        return parseFunction(mapHydraCollection(response.data))
    }
}

export const httpService = new HttpService()

export const commonService = {

    uploadFile: async (file: File): Promise<TApiFile> => {
        const data = new FormData()
        data.append('file', file)

        const response = await httpService.request<TApiFile>({
            url: '/files/upload',
            method: 'POST',
            data
        })

        return response.data
    },

}