import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    ProviderProps,
    ProviderUpdateProps,
    TProviderState,
} from '../../types'
import { organizationService } from '../../../services'
import { parseHttpError } from '../../utils'
import { parseProviders } from './utils'
import { selectors } from './selectors'
import { providerAdapter } from './adapter'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'

const name = 'providers'

const initialState: TProviderState = {
    ...providerAdapter.getInitialState(),
    isFetching: false,
    totalItems: 0,
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async (params: FetchCreatedAtParams & FetchCollectionParams | undefined) => {
        const order = params?.order || {'order[name]': 'asc'}

        return parseProviders(await organizationService.fetchProviders({
            pagination: params?.pagination,
            page: params?.page,
            perPage: params?.perPage,
            ...order
        }))
    },
    {
        serializeError: parseHttpError
    }
)

const create = createAsyncThunk(
    `${name}/create`,
    async (data: ProviderProps) => {
        return await organizationService.createProvider(data)
    },
    {
        serializeError: parseHttpError
    }
)

const update = createAsyncThunk(
    `${name}/create`,
    async (data: ProviderUpdateProps) => {
        return await organizationService.updateProvider(data)
    },
    {
        serializeError: parseHttpError
    }
)

const remove = createAsyncThunk(
    `${name}/remove`,
    async (id: string) => {
        return await organizationService.removeProvider(id)
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                state.totalItems = action.payload.totalItems
                providerAdapter.setAll(state, action.payload.entities)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const providers = {
    name: slice.name,
    actions: {
        fetch,
        create,
        update,
        remove,
    },
    reducer: slice.reducer,
    selectors,
}