import { TApiAttributeGroup, TApiAttributeGroups } from '../../../services/product/types'
import { ICollection } from '../../../services/_common'
import { IAttributeGroup } from '../../types'
import { userTransformer } from '../users/utils'

export function parseAttributeGroups(response: TApiAttributeGroups): ICollection<IAttributeGroup> {
    return {
        entities: response.entities.map(attributeGroupTransformer),
        totalItems: response.totalItems
    }
}

export const attributeGroupTransformer = (apiObject: TApiAttributeGroup) : IAttributeGroup => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt
})