import { ROUTES } from '../../constants'
import { httpService, HydraCollectionResponse, TApiEnum, TApiEnums, UpdateEntityProps } from '../_common'
import {
    AttributeGroupProps,
    AttributeProps,
    StorageProps,
    CategoryProps,
    ModelUpdateProps,
} from '../../store/types'
import { AxiosResponse } from 'axios'
import {
    TApiAttribute,
    TApiAttributeGroup,
    TApiCategory,
    TApiModel,
    TApiModelPreset,
    TApiOption,
    TApiPrice,
    TApiStorage,
} from './types'
import { OptionProps } from '../../store/types'
import { mapHydraCollection } from '../_common/utils'
import { PriceProps } from '../../store/types'
import { ModelPresetProps } from '../../store/types/modelPreset'

export const navLinksProduct = [
    //{ path: ROUTES.PRODUCT, text: 'Товары', role: 'USER'},
    { path: ROUTES.PRODUCT_ATTRIBUTES, text: 'Атрибуты', role: 'USER'},
    { path: ROUTES.PRODUCT_ATTRIBUTE_GROUPS, text: 'Группы атрибутов', role: 'USER'},
    { path: ROUTES.PRODUCT_OPTIONS, text: 'Опции', role: 'USER'},
    { path: ROUTES.PRODUCT_STORAGES, text: 'Склады', role: 'USER'},
    { path: ROUTES.PRODUCT_CATEGORIES, text: 'Категории', role: 'USER'},
    { path: ROUTES.PRODUCT_MODELS, text: 'Модели', role: 'USER'},
    { path: ROUTES.PRODUCT_PRICES, text: 'Цены', role: 'USER'},
]

export const productService = {

    createAttribute: async (attribute = {}): Promise<TApiAttribute> => {
        const response = await httpService.request<TApiAttribute>({
            url: '/attributes',
            method: 'POST',
            data: attribute
        })

        return response.data
    },

    updateAttribute: async (updateEntityProps: UpdateEntityProps<AttributeProps>): Promise<TApiAttribute> => {
        const response = await httpService.request<TApiAttribute>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeAttribute: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/attributes/${id}`,
            method: 'DELETE',
        })
    },

    createAttributeGroup: async (attributeGroup = {}): Promise<TApiAttributeGroup> => {
        const response = await httpService.request<TApiAttributeGroup>({
            url: '/attribute_groups',
            method: 'POST',
            data: attributeGroup
        })

        return response.data
    },

    updateAttributeGroup: async (updateEntityProps: UpdateEntityProps<AttributeGroupProps>): Promise<TApiAttributeGroup> => {
        const response = await httpService.request<TApiAttributeGroup>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeAttributeGroup: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/attribute_groups/${id}`,
            method: 'DELETE',
        })
    },

    createOption: async (option = {}): Promise<TApiOption> => {
        const response = await httpService.request<TApiOption>({
            url: '/options',
            method: 'POST',
            data: option
        })

        return response.data
    },

    updateOption: async (updateEntityProps: UpdateEntityProps<OptionProps>): Promise<TApiOption> => {
        const response = await httpService.request<TApiOption>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeOption: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/options/${id}`,
            method: 'DELETE',
        })
    },

    createStorage: async (storage = {}): Promise<TApiStorage> => {
        const response = await httpService.request<TApiStorage>({
            url: '/storages',
            method: 'POST',
            data: storage
        })

        return response.data
    },

    updateStorage: async (updateEntityProps: UpdateEntityProps<StorageProps>): Promise<TApiStorage> => {
        const response = await httpService.request<TApiStorage>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeStorage: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/storages/${id}`,
            method: 'DELETE',
        })
    },

    createCategory: async (category = {}): Promise<TApiCategory> => {
        const response = await httpService.request<TApiCategory>({
            url: '/categories',
            method: 'POST',
            data: category
        })

        return response.data
    },

    updateCategory: async (updateEntityProps: UpdateEntityProps<CategoryProps>): Promise<TApiCategory> => {
        const response = await httpService.request<TApiCategory>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeCategory: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/categories/${id}`,
            method: 'DELETE',
        })
    },

    createModel: async (model = {}): Promise<TApiModel> => {
        const response = await httpService.request<TApiModel>({
            url: '/models',
            method: 'POST',
            data: model
        })

        return response.data
    },

    updateModel: async (updateEntityProps: UpdateEntityProps<ModelUpdateProps>): Promise<TApiModel> => {
        const response = await httpService.request<TApiModel>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeModel: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/models/${id}`,
            method: 'DELETE',
        })
    },

    fetchModelOptionTypes: async (): Promise<TApiEnums> => {
        const response = await httpService.request<HydraCollectionResponse<TApiEnum>>({
            url: '/model_option_types',
            method: 'get',
        })
        return mapHydraCollection(response.data)
    },

    fetchPriceTypes: async (): Promise<TApiEnums> => {
        const response = await httpService.request<HydraCollectionResponse<TApiEnum>>({
            url: '/price_types',
            method: 'get',
        })
        return mapHydraCollection(response.data)
    },


    createPrice: async (price = {}): Promise<TApiPrice> => {
        const response = await httpService.request<TApiPrice>({
            url: '/prices',
            method: 'POST',
            data: price
        })

        return response.data
    },

    updatePrice: async (updateEntityProps: UpdateEntityProps<PriceProps>): Promise<TApiPrice> => {
        const response = await httpService.request<TApiPrice>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removePrice: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/prices/${id}`,
            method: 'DELETE',
        })
    },

    createModelPreset: async (modelPreset = {}): Promise<TApiModelPreset> => {
        const response = await httpService.request<TApiModelPreset>({
            url: '/model_presets',
            method: 'POST',
            data: modelPreset
        })

        return response.data
    },

    updateModelPreset: async (updateEntityProps: UpdateEntityProps<ModelPresetProps>): Promise<TApiModelPreset> => {
        const response = await httpService.request<TApiModelPreset>({
            url: updateEntityProps.iri,
            method: 'PUT',
            data: updateEntityProps.data,
        })

        return response.data
    },

    removeModelPreset: async (id: string): Promise<AxiosResponse> => {
        return await httpService.request<AxiosResponse>({
            url: `/model_presets/${id}`,
            method: 'DELETE',
        })
    },
}