import { TAttributesState, TRootState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { attributesAdapter } from './adapter'

const selectState = (state: TRootState): TAttributesState => state.attributes

const adapterSelectors = attributesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
}