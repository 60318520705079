import { TRootState, TSpendingTypesState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { spendingTypesAdapter } from './adapter'

const selectState = (state: TRootState): TSpendingTypesState => state.spendingTypes

const adapterSelectors = spendingTypesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)


const selectActive = createSelector(
    adapterSelectors.selectAll,
    (state) => state.filter((item) => item.active)
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems,
    selectActive,
}