import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../constants'
import { auth } from '../../store/slices'

export function PrivateRoute(props: RouteProps): JSX.Element {
    const user = useSelector(auth.selectors.user)

    if (user) {
        return (
            <Route {...props}/>
        )
    }

    return (
        <Redirect to={ROUTES.SIGN_IN} />
    )
}