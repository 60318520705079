import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid
} from '@material-ui/core'
import { attributeGroups } from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { ROUTES } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { validation } from '../../../../services'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { navLinksProduct } from '../../../../services/product'

export function AttributeGroupCreatePage(): JSX.Element {
    useEffect(() => {
        dispatch(attributeGroups.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const history = useHistory()

    const initialValues = {
        name: ''
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(attributeGroups.actions.create(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.PRODUCT_ATTRIBUTE_GROUPS)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksProduct}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Создание группы атрибутов'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}