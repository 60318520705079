import { ISpendingType } from '../../types'
import { TApiSpendingType, TApiSpendingTypes } from '../../../services/moneyFlow/types'
import { userTransformer } from '../users/utils'
import { enumTransformer } from '../../../services/_common/utils'
import { ICollection } from '../../../services/_common'

export function parseSpendingTypes(response: TApiSpendingTypes): ICollection<ISpendingType> {
    return {
        entities: response.entities.map(spendingTypeTransformer),
        totalItems: response.totalItems
    }
}

export const spendingTypeTransformer = (apiObject: TApiSpendingType) : ISpendingType => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    active: apiObject.active,
    requiredJuridicalPerson: apiObject.requiredJuridicalPerson,
    expenditureType: enumTransformer(apiObject.expenditureType),
    creator: userTransformer(apiObject.creator),
    createdAt: apiObject.createdAt
})