import React from 'react'
import {
    GridToolbarContainer, GridColumns,
} from '@material-ui/data-grid'
import { Grid } from '@material-ui/core'
import { RefreshButton } from './RefreshButton'
import { ExportButton } from './ExportButton'
import { DataTableFilter, ExportFunction, MappingFunction } from './types'
import { Filter } from './Filter'
import { theme } from '../../theme'


export function Toolbar<T, FetchProps>({
    loading,
    exportFunction,
    exportMappingFunction,
    headerExportColumns,
    filters,
}: {
    columns: GridColumns,
    loading: boolean,
    exportFunction?: ExportFunction<T, FetchProps>
    exportMappingFunction?: MappingFunction<T>,
    headerExportColumns?: Array<string>
    filters?: Array<DataTableFilter>|undefined,
}): JSX.Element {
    return (
        <GridToolbarContainer style={{padding: theme.spacing(1)}}>
            <Grid container>
                { filters ?
                    <Grid item xs={12} style={{marginBottom: theme.spacing(2)}}>
                        <Grid container spacing={2} >
                            {filters.map((filter, key) => <Filter key={key} {...filter}/>)}
                        </Grid>
                    </Grid>
                    : null
                }
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item>
                            <RefreshButton disabled={loading}/>
                        </Grid>
                        {exportFunction && headerExportColumns && exportMappingFunction? <Grid item>
                            <ExportButton<T, FetchProps> exportFunction={exportFunction} mappingFunction={exportMappingFunction} headerExportColumns={headerExportColumns} disabled={loading}/>
                        </Grid> : null}
                    </Grid>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    )
}