import { ICollection } from '../../../services/_common'
import {
    TApiModelPreset, TApiModelPresets,
} from '../../../services/product/types'
import { IModelPreset } from '../../types/modelPreset'
import { modelAttributeTransformer, modelOptionTransformer } from '../models/utils'


export function parseModelPresets(response: TApiModelPresets): ICollection<IModelPreset> {
    return {
        entities: response.entities.map(modelTransformer),
        totalItems: response.totalItems
    }
}

export const modelTransformer = (apiObject: TApiModelPreset) : IModelPreset => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    data: {
        options: apiObject.data.options.map( modelOptionTransformer ),
        attributes: apiObject.data.attributes.map( modelAttributeTransformer ),
    }

})