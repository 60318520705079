import { Route, RouteProps, Switch } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { AttributesPage } from '../../pages/product/attributes'
import { PrivateRoute } from '../../components/private-route'
import { NotFoundPage } from '../../pages/not-found'
import React from 'react'
import { AttributeGroupsPage } from '../../pages/product/attributeGroup'
import { AttributeGroupCreatePage } from '../../pages/product/attributeGroup/create'
import { AttributeGroupEditPage } from '../../pages/product/attributeGroup/edit'
import { AttributeCreatePage } from '../../pages/product/attributes/create'
import { AttributeEditPage } from '../../pages/product/attributes/edit'
import { OptionsPage } from '../../pages/product/option'
import { OptionCreatePage } from '../../pages/product/option/create'
import { OptionEditPage } from '../../pages/product/option/edit'
import { StoragesPage } from '../../pages/product/storage'
import { StorageCreatePage } from '../../pages/product/storage/create'
import { StorageEditPage } from '../../pages/product/storage/edit'
import { CategoriesPage } from '../../pages/product/categories'
import { CategoryCreatePage } from '../../pages/product/categories/create'
import { CategoryEditPage } from '../../pages/product/categories/edit'
import { ModelsPage } from '../../pages/product/models'
import { ModelCreatePage } from '../../pages/product/models/create'
import { ModelEditPage } from '../../pages/product/models/edit'
import { PricesPage } from '../../pages/product/price'

const privateRoutes: Array<RouteProps> = [
    {
        path: ROUTES.PRODUCT,
        component: AttributesPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_ATTRIBUTES,
        component: AttributesPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_ATTRIBUTES_CREATE,
        component: AttributeCreatePage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_ATTRIBUTES_EDIT,
        component: AttributeEditPage
    },
    {
        path: ROUTES.PRODUCT_ATTRIBUTE_GROUPS,
        component: AttributeGroupsPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_ATTRIBUTE_GROUPS_CREATE,
        component: AttributeGroupCreatePage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_ATTRIBUTE_GROUPS_EDIT,
        component: AttributeGroupEditPage
    },
    {
        path: ROUTES.PRODUCT_OPTIONS,
        component: OptionsPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_OPTIONS_CREATE,
        component: OptionCreatePage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_OPTIONS_EDIT,
        component: OptionEditPage
    },
    {
        path: ROUTES.PRODUCT_STORAGES,
        component: StoragesPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_STORAGES_CREATE,
        component: StorageCreatePage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_STORAGES_EDIT,
        component: StorageEditPage
    },
    {
        path: ROUTES.PRODUCT_CATEGORIES,
        component: CategoriesPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_CATEGORIES_CREATE,
        component: CategoryCreatePage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_CATEGORIES_EDIT,
        component: CategoryEditPage
    },
    {
        path: ROUTES.PRODUCT_MODELS,
        component: ModelsPage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_MODELS_CREATE,
        component: ModelCreatePage,
        exact: true,
    },
    {
        path: ROUTES.PRODUCT_MODELS_EDIT,
        component: ModelEditPage
    },

    {
        path: ROUTES.PRODUCT_PRICES,
        component: PricesPage,
        exact: true,
    },
]

export function Product(): JSX.Element {
    return (
        <Switch>
            {privateRoutes.map((route) => <PrivateRoute key={String(route.path)} {...route} />)}
            <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
        </Switch>
    )
}