import React, { useCallback } from 'react'
import { useStyles } from './datePeriodField.style'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FieldProps } from 'formik'
import { DatePickerProps as MuiDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker'
import { FormControl, Grid, IconButton, InputLabel } from '@material-ui/core'
import { FetchCreatedAtParam } from '../../services/_common'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CloseIcon from '@material-ui/icons/Close'


export interface DatePeriodFieldProps extends FieldProps<FetchCreatedAtParam>, Omit<MuiDatePickerProps, 'name' | 'value' | 'error'> {
    label: string,
}

export function DatePeriodField({
    disabled,
    required,
    label,
    ...props
}: DatePeriodFieldProps): JSX.Element {

    const classes = useStyles()

    const {
        form: { setFieldValue },
        field: { name, value },
    } = props

    const onChangeAfterDate = useCallback(
        (date: MaterialUiPickersDate) => {
            setFieldValue(name, {
                ...value,
                after: date?.startOf('day').toISOString() || null,
            })
        },
        [setFieldValue, name],
    )

    const onChangeBeforeDate = useCallback(
        (date: MaterialUiPickersDate) => {
            setFieldValue(name, {
                ...value,
                before: date?.endOf('day').toISOString() || null,
            })
        },
        [setFieldValue, name],
    )

    const onReset = useCallback(
        () => {
            setFieldValue(name, {
                after: null,
                before: null
            })
        },
        [setFieldValue, name]
    )

    return (
        <FormControl
            className={classes.root}
            fullWidth
            disabled={disabled}
        >
            { label ? <InputLabel shrink disabled={disabled} required={required}>{label}</InputLabel> : null }
            <Grid container spacing={1} justify="space-between" alignItems="flex-start">
                <Grid item xs={5}>
                    <KeyboardDatePicker
                        placeholder="От"
                        value={value?.after || null}
                        onChange={onChangeAfterDate}
                        format="yyyy/MM/DD"
                        className={classes.field}
                        InputProps={{
                            margin: 'dense'
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <KeyboardDatePicker
                        placeholder="До"
                        value={value?.before || null}
                        onChange={onChangeBeforeDate}
                        format="yyyy/MM/DD"
                        className={classes.field}

                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton
                        onClick={onReset}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </FormControl>
    )
}