import { httpService } from '../_common'
import { TApiSaleTest } from './types'

class SaleService {
    async fetch() {
        const response = await httpService.request<TApiSaleTest>({
            url: '/sale/test',
            method: 'get'
        })
        
        return response.data
    }
}

export const saleService = new SaleService()
