import { createMuiTheme } from '@material-ui/core'
import { ruRU } from '@material-ui/core/locale'

export function getLabelTransform(offsetY: number): string {
    return `translate(0, ${offsetY}px) scale(1)`
}


export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e65100',
        },
        secondary: {
            main: '#ffd600',
        },
    },
    props: {
        MuiButton: {
            variant: 'contained',
        },
    },
    overrides: {

    }
}, ruRU)
