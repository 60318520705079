import React, { ChangeEvent, useCallback } from 'react'
import { useStyles } from './select-field-multiple.style'
import { TextFieldProps } from 'formik-material-ui'
import MuiTextField from '@material-ui/core/TextField'
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core'


interface SelectFieldMultipleProps extends TextFieldProps {
    items: Array<{
        value: string,
        label: string
    }>,
}

export function SelectFieldMultiple({
    items,
    field: { name, value },
    form: { setFieldValue },
    ...props
}: SelectFieldMultipleProps): JSX.Element {

    const classes = useStyles()

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            setFieldValue(name, value)
        },
        [setFieldValue, name],
    )

    return (
        <MuiTextField
            {...props}
            name={name}
            value={value || []}
            className={classes.selectFieldMultiple}
            select
            onChange={onChange}
            SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                    if(Array.isArray(selected)) {
                        return items.filter((item) => selected.indexOf(item.value) > -1 ).map((item) => item.label).join(', ')
                    }
                },
            }}
        >
            {items.map((item, idx) =>
                <MenuItem key={idx} value={item.value}>
                    <Checkbox checked={value && Array.isArray(value) && value.indexOf(item.value) > -1} />
                    <ListItemText primary={item.label} />
                </MenuItem>
            )}
        </MuiTextField>
    )
}