import { AxiosError } from 'axios'
import { SerializedError } from '@reduxjs/toolkit'

export function parseHttpError(x: unknown): SerializedError {
    const error = x as AxiosError
    if (error.response) {
        return {
            code: error.response.data?.code || error.response.status,
            message: error.response.data?.message || error.message,
        }
    }

    return {
        code: error.code,
        message: error.message,
    }
}