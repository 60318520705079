export const ROUTES = {
    ROOT: '/',
    SIGN_IN: '/sign-in',
    SALE: '/sale',

    MONEY_FLOW: '/money-flow',

    MONEY_FLOW_SPENDINGS: '/money-flow/spendings/',
    MONEY_FLOW_SPENDINGS_CREATE: '/money-flow/spendings/create',
    MONEY_FLOW_SPENDINGS_EDIT: '/money-flow/spendings/edit/:id',

    MONEY_FLOW_SPENDING_TYPES: '/money-flow/spending-types',
    MONEY_FLOW_SPENDING_TYPES_CREATE: '/money-flow/spending-types/create',
    MONEY_FLOW_SPENDING_TYPES_EDIT: '/money-flow/spending-types/edit/:id',

    MONEY_FLOW_SUB_SPENDING_TYPES: '/money-flow/sub-spending-types',
    MONEY_FLOW_SUB_SPENDING_TYPES_CREATE: '/money-flow/sub-spending-types/create',
    MONEY_FLOW_SUB_SPENDING_TYPES_EDIT: '/money-flow/sub-spending-types/edit/:id',

    ORGANIZATION: '/organization/',
    ORGANIZATION_BUSINESS_UNITS: '/organization/business-units',
    ORGANIZATION_BUSINESS_UNITS_CREATE: '/organization/business-units/create',
    ORGANIZATION_BUSINESS_UNITS_EDIT: '/organization/business-units/edit/:id',

    ORGANIZATION_JURIDICAL_PERSONS: '/organization/juridical-persons',
    ORGANIZATION_JURIDICAL_PERSONS_CREATE: '/organization/juridical-persons/create',
    ORGANIZATION_JURIDICAL_PERSONS_EDIT: '/organization/juridical-persons/edit/:id',

    ORGANIZATION_PROVIDERS: '/organization/providers',
    ORGANIZATION_PROVIDERS_CREATE: '/organization/providers/create',
    ORGANIZATION_PROVIDERS_EDIT: '/organization/providers/edit/:id',

    PRODUCT: '/product/',

    PRODUCT_ATTRIBUTES: '/product/attributes',
    PRODUCT_ATTRIBUTES_CREATE: '/product/attributes/create',
    PRODUCT_ATTRIBUTES_EDIT: '/product/attributes/edit/:id',

    PRODUCT_ATTRIBUTE_GROUPS: '/product/attribute-groups',
    PRODUCT_ATTRIBUTE_GROUPS_CREATE: '/product/attribute-groups/create',
    PRODUCT_ATTRIBUTE_GROUPS_EDIT: '/product/attribute-groups/edit/:id',

    PRODUCT_OPTIONS: '/product/options',
    PRODUCT_OPTIONS_CREATE: '/product/options/create',
    PRODUCT_OPTIONS_EDIT: '/product/options/edit/:id',

    PRODUCT_STORAGES: '/product/storages',
    PRODUCT_STORAGES_CREATE: '/product/storages/create',
    PRODUCT_STORAGES_EDIT: '/product/storages/edit/:id',

    PRODUCT_CATEGORIES: '/product/categories',
    PRODUCT_CATEGORIES_CREATE: '/product/categories/create',
    PRODUCT_CATEGORIES_EDIT: '/product/categories/edit/:id',

    PRODUCT_MODELS: '/product/models',
    PRODUCT_MODELS_CREATE: '/product/models/create',
    PRODUCT_MODELS_EDIT: '/product/models/edit/:id',

    PRODUCT_PRICES: '/product/prices',

    NOT_FOUND: '*',
}
