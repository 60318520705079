import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {

    }
}))
