import { httpService } from '../_common'
import { TApiToken } from './types'

export const authService = {
    signIn: async (email: string, password: string): Promise<TApiToken> => {
        const response = await httpService.request<TApiToken>({
            url: '/token',
            method: 'POST',
            data: {
                email,
                password
            }
        }, true)

        return response.data
    }
}