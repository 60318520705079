import { TRootState, TExpenditureTypesState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { expenditureTypesAdapter } from './adapter'

const selectState = (state: TRootState): TExpenditureTypesState => state.expenditureTypes

const adapterSelectors = expenditureTypesAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus
}