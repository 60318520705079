import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    sideBar: {
        position: 'relative',
        width: 300,
        backgroundColor: theme.palette.background.default,
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1)
    },
    sideBarPaper: {
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
        height: '100%'
    },
    sideBarDivider: {
        marginTop:  theme.spacing(1),
        marginLeft:  theme.spacing(2),
        marginRight:  theme.spacing(2),
        marginBottom:  theme.spacing(1),
    }
}))