import { IPrice } from '../../types'
import {
    TApiPrice, TApiPrices,
} from '../../../services/product/types'
import { enumTransformer } from '../../../services/_common/utils'
import { ICollection } from '../../../services/_common'


export const priceTransformer = (apiObject: TApiPrice) : IPrice => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    type: enumTransformer(apiObject.type),
    actionValue: apiObject.actionValue,
    actionPrice: apiObject.actionPrice,
    dateStart: apiObject.dateStart,
    dateEnd: apiObject.dateEnd,
    model: apiObject.model,
    product: apiObject.product,
    price: apiObject.price,
    costPrice: apiObject.costPrice,
    modelName: apiObject?.modelName,
})

export function parsePrices(response: TApiPrices): ICollection<IPrice> {
    return {
        entities: response.entities.map(priceTransformer),
        totalItems: response.totalItems
    }
}