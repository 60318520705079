import { ICollection } from '../../../services/_common'
import { IAttribute } from '../../types'
import { TApiAttribute, TApiAttributes } from '../../../services/product/types'
import { attributeGroupTransformer } from '../attributeGroups/utils'


export function parseAttributes(response: TApiAttributes): ICollection<IAttribute> {
    return {
        entities: response.entities.map(attributeTransformer),
        totalItems: response.totalItems
    }
}

export const attributeTransformer = (apiObject: TApiAttribute) : IAttribute => ({
    iri: apiObject['@id'],
    id: apiObject.id,
    name: apiObject.name,
    description: apiObject.description,
    values: apiObject.values,
    group: apiObject.group ? attributeGroupTransformer(apiObject.group) : null,
})