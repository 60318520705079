import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid
} from '@material-ui/core'
import {
    spendingTypes,
    subSpendingTypes,
} from '../../../../store/slices'
import { useAppDispatch } from '../../../../store'
import { ROUTES } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksMoneyFlow, validation } from '../../../../services'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { SelectField } from '../../../../components/selectField'
import { useSelector } from 'react-redux'

export function SubSpendingTypeCreatePage(): JSX.Element {
    const dispatch = useAppDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(spendingTypes.actions.fetch({pagination: false}))
    }, [])

    const spendingTypesList = useSelector(spendingTypes.selectors.selectActive)

    const initialValues = {
        name: '',
        active: false,
        spendingType: '',
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                dispatch(subSpendingTypes.actions.create(values))
                    .then(unwrapResult)
                    .then(() => {
                        history.push(ROUTES.MONEY_FLOW_SUB_SPENDING_TYPES)
                    })
                setSubmitting(false)
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <Box display="flex">
                        <SideBar navLinks={navLinksMoneyFlow}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Сохранить
                            </Button>
                        </SideBar>
                        <Content loading={false} notFoundContent={false} title={'Создание подстатьи расходов'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        label="Название"
                                        name="name"
                                        fullWidth
                                        validate={validation.required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'Активна' }}
                                        name="active"
                                        type="checkbox"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label='Статья расхода'
                                        name='spendingType'
                                        component={SelectField}
                                        validate={validation.required}
                                        required
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={spendingTypesList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}