import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
} from '@material-ui/core'
import {
    businessUnits,
    CreateSpendingProps, juridicalPersons,
    paymentTypes,
    spendings,
    spendingTypes, subSpendingTypes,
} from '../../../../store/slices'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { SideBar } from '../../../../components/side-bar'
import { Content } from '../../../../components/content'
import { navLinksMoneyFlow, validation } from '../../../../services'
import { Field, Form, Formik, useField, useFormikContext } from 'formik'
import { TextField } from 'formik-material-ui'
import moment from 'moment'
import { SelectField } from '../../../../components/selectField'
import { DatePicker } from 'formik-material-ui-pickers'
import { useSelector } from 'react-redux'
import { NumericField } from '../../../../components/numericField'
import { FieldAttributes } from 'formik/dist/Field'
import { getIdFromIRI } from '../../../../services/_common/utils'
import { providers } from '../../../../store/slices/provider'

interface SpendingEditPageProps {
    match: {
        params: {
            id: string
        }
    }
}

interface IInitialValues {
    deal: string|null,
    name: string|null,
    businessUnit: string|null,
    paymentType: string|null,
    spendingType: string|null,
    subSpendingType: string|null,
    amount: number,
    debtOnBusinessUnit: string|null,
    comment: string|null,
    actualDate: string|undefined,
    juridicalPerson: string|null,
    provider: string|null,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyField = ({ ...props }: FieldAttributes<any>) => {
    const {
        values,
        setFieldValue,
    } = useFormikContext<IInitialValues>()
    const [field] = useField(props)
    const { spendingType, subSpendingType } = values
    const subSpendingTypesList = useSelector(subSpendingTypes.selectors.selectBySpendingTypeId(getIdFromIRI(spendingType??'')))

    React.useEffect(() => {
        if(subSpendingTypesList.length > 0 && !subSpendingType) {
            setFieldValue(props.name, subSpendingTypesList[0].iri)
        }
    }, [spendingType])

    return (
        <Field {...props} {...field} items={subSpendingTypesList.map((item) => ({value: item.iri, label: item.name}))} />
    )
}

export function SpendingEditPage(props: SpendingEditPageProps): JSX.Element {
    const dispatch = useAppDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(businessUnits.actions.fetch({pagination:false}))
        dispatch(paymentTypes.actions.fetch())
        dispatch(spendingTypes.actions.fetch({pagination:false}))
        dispatch(subSpendingTypes.actions.fetch({pagination:false}))
        dispatch(juridicalPersons.actions.fetch({pagination:false}))
        dispatch(providers.actions.fetch({pagination:false}))
    }, [])

    const paymentTypesList = useSelector(paymentTypes.selectors.selectAll)
    const businessUnitsList = useSelector(businessUnits.selectors.selectAll)
    const spendingTypesList = useSelector(spendingTypes.selectors.selectActive)
    const juridicalPersonsList = useSelector(juridicalPersons.selectors.selectAll)
    const providersList = useSelector(providers.selectors.selectAll)

    const spending = useAppSelector((state) => spendings.selectors.selectById(state, props.match.params.id))


    const initialValues: IInitialValues = {
        deal: spending?.iri || '',
        name: spending?.name || '',
        amount: spending?.amount.amount || 0,
        businessUnit: spending?.businessUnit.iri || null,
        debtOnBusinessUnit: spending?.debtDeal ? spending.debtDeal.businessUnit.iri : null,
        spendingType: spending?.spendingType.iri || null,
        subSpendingType: spending?.subSpendingType?.iri || null,
        paymentType: spending?.paymentType.iri || null,
        actualDate: spending?.actualDate,
        comment: spending?.comment || '',
        juridicalPerson: spending?.juridicalPerson ? spending.juridicalPerson.iri : null,
        provider: spending?.provider ? spending.provider.iri : null,
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {

                const data: CreateSpendingProps = {
                    ...values,
                    actualDate: moment(values.actualDate).format('YYYY-MM-DD'),
                    amount: {
                        amount: Math.round(values.amount*100),
                        currency: 'RUB'
                    }
                }
                dispatch(spendings.actions.create(data))
                    .then(unwrapResult)
                    .then(() => {
                        history.goBack()
                    })
                setSubmitting(false)
            }}
            enableReinitialize
        >
            {({ submitForm, values }) => (
                <Box display="flex">
                    <SideBar navLinks={navLinksMoneyFlow}>
                        <Button
                            fullWidth={true}
                            variant={'contained'}
                            onClick={submitForm}
                        >
                            Сохранить
                        </Button>
                    </SideBar>
                    <Content loading={false} notFoundContent={false} title={'Создание расхода'}>
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Field
                                        label="Бизнес юнит"
                                        name="businessUnit"
                                        component={SelectField}
                                        validate={validation.required}
                                        required
                                        fullWidth
                                        nullable
                                        nullableText="Не выбрано"
                                        items={businessUnitsList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label="Статья расхода"
                                        name="spendingType"
                                        component={SelectField}
                                        validate={validation.required}
                                        required
                                        fullWidth
                                        nullable
                                        nullableText="Не выбрано"
                                        items={spendingTypesList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <MyField
                                        label={'Подстатья расхода'}
                                        name={'subSpendingType'}
                                        component={SelectField}
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={spendingTypesList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label="Тип оплаты"
                                        name="paymentType"
                                        component={SelectField}
                                        validate={validation.required}
                                        required
                                        fullWidth
                                        nullable
                                        nullableText="Не выбрано"
                                        items={paymentTypesList.map((item) => ({value: item.iri, label: item.text}))}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name={'amount'}
                                        component={NumericField}
                                        required
                                        type="number"
                                        label="Сумма"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label="Бизнес юнит за который будет внесена оплата"
                                        name="debtOnBusinessUnit"
                                        component={SelectField}
                                        fullWidth
                                        nullable
                                        nullableText="Не выбрано"
                                        items={businessUnitsList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        name="comment"
                                        component={TextField}
                                        label="Комментарий"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label="Факстическая дата списания"
                                        name="actualDate"
                                        component={DatePicker}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label={'Поставщик'}
                                        name="provider"
                                        component={SelectField}
                                        validate={validation.required}
                                        required
                                        fullWidth
                                        nullable
                                        nullableText={'Не выбрано'}
                                        items={providersList.map((item) => ({value: item.iri, label: item.name}))}
                                    />
                                </Grid>
                                { spendingTypesList.find((item) => item.iri === values.spendingType)?.requiredJuridicalPerson
                                    ? <Grid item xs={3}>
                                        <Field
                                            label={'Юр лицо'}
                                            name="juridicalPerson"
                                            component={SelectField}
                                            validate={validation.required}
                                            required
                                            fullWidth
                                            nullable
                                            nullableText={'Не выбрано'}
                                            items={juridicalPersonsList.map((item) => ({value: item.iri, label: item.name}))}
                                        />
                                    </Grid>
                                    : null
                                }
                            </Grid>
                        </Form>
                    </Content>
                </Box>
            )}
        </Formik>
    )
}