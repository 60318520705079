import { TRootState, TSpendingsState } from '../../types'
import { createSelector } from '@reduxjs/toolkit'
import { spendingsAdapter } from './adapter'

const selectState = (state: TRootState): TSpendingsState => state.spendings

const adapterSelectors = spendingsAdapter.getSelectors(selectState)

const fetchStatus = createSelector(
    selectState,
    (state) => ({
        isFetching: state.isFetching,
        error: state.fetchError,
    })
)

const totalItems = createSelector(
    selectState,
    (state) => (state.totalItems)
)

export const selectors = {
    ...adapterSelectors,
    fetchStatus,
    totalItems
}