import React, { useEffect } from 'react'
import {
    Box,
    Button,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { subSpendingTypes } from '../../../store/slices'
import { useAppDispatch } from '../../../store'
import { SideBar } from '../../../components/side-bar'
import { navLinksMoneyFlow, renderUtils } from '../../../services'
import { getIdFromIRI } from '../../../services/_common/utils'
import { Link } from 'react-router-dom'
import { DataTable } from '../../../components/dataTable'
import { ROUTES } from '../../../constants'
import { ISubSpendingType } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'

export function SubSpendingTypesPage(): JSX.Element {
    useEffect(() => {
        dispatch(subSpendingTypes.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const fetchStatus = useSelector(subSpendingTypes.selectors.fetchStatus)
    const totalItems = useSelector(subSpendingTypes.selectors.selectTotal)

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksMoneyFlow}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.MONEY_FLOW_SUB_SPENDING_TYPES_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<ISubSpendingType, FetchCreatedAtParams & FetchCollectionParams>
                title={'Подстатьи расходов'}
                urlPathEdit={'/money-flow/sub-spending-types/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={subSpendingTypes.actions.fetch}
                selector={subSpendingTypes.selectors.selectAll}
                deleteAction={subSpendingTypes.actions.remove}
                mappingFunction={(subSpendingType) => ({
                    id: getIdFromIRI(subSpendingType.id),
                    name: subSpendingType.name,
                    spendingType: subSpendingType?.spendingType.name,
                    active: subSpendingType.active,
                })}
                loading={fetchStatus.isFetching}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true },
                    { field: 'name', headerName: 'Название', flex: 1, sortable: true },
                    { field: 'spendingType', headerName: 'Статья расхода', flex: 1, sortable: true },
                    { field: 'active', headerName: 'Активна', flex: 1, renderCell: renderUtils.renderBoolean, sortable: false },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}