import React, { useCallback } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FieldProps } from 'formik'
import { DatePickerProps as MuiDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker'
import { FetchCreatedAtParam, SimpleEvent } from '../../services/_common'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'


export interface DateFieldProps extends FieldProps<FetchCreatedAtParam>, Omit<MuiDatePickerProps, 'name' | 'value' | 'error' | 'onChange'> {
    label: string,
    onChangeSimple: (event: SimpleEvent) => void
}

export function DateField({
    label,
    ...props
}: DateFieldProps): JSX.Element {


    const {
        form: { setFieldValue },
        field: { name, value },
    } = props

    const onChange = useCallback(
        (date: MaterialUiPickersDate) => {
            const value = date?.toISOString() || null
            props.onChangeSimple && props.onChangeSimple({target: { name: name, value: value }})
            setFieldValue(name, value)
        },
        [setFieldValue, name],
    )



    return (
        <KeyboardDatePicker
            label={label}
            value={value || null}
            onChange={onChange}
        />
    )
}