import React, { useEffect } from 'react'
import {
    Box,
    Button,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    businessUnits, juridicalPersons, paymentTypes,
    spendings, spendingTypes,
    subSpendingTypes,
} from '../../../store/slices'
import { useAppDispatch } from '../../../store'
import { SideBar } from '../../../components/side-bar'
import { moneyFlowService, navLinksMoneyFlow, renderUtils } from '../../../services'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { DataTable } from '../../../components/dataTable'
import { ROUTES } from '../../../constants'
import { FetchSpendingProps, ISpending } from '../../../store/types'
import { providers } from '../../../store/slices/provider'


export function SpendingsPage(): JSX.Element {

    useEffect(() => {
        dispatch(subSpendingTypes.actions.fetch({pagination: false}))
        dispatch(spendingTypes.actions.fetch({pagination: false}))
        dispatch(businessUnits.actions.fetch({pagination: false}))
        dispatch(juridicalPersons.actions.fetch({pagination: false}))
        dispatch(providers.actions.fetch({pagination: false}))
        dispatch(paymentTypes.actions.fetch())
    }, [])

    const dispatch = useAppDispatch()
    const fetchStatus = useSelector(spendings.selectors.fetchStatus)
    const totalItems = useSelector(spendings.selectors.totalItems)
    const businessUnitsList = useSelector(businessUnits.selectors.selectAll)
    const paymentTypesList = useSelector(paymentTypes.selectors.selectAll)
    const subSpendingTypesList = useSelector(subSpendingTypes.selectors.selectActive)
    const spendingTypesList = useSelector(spendingTypes.selectors.selectActive)
    const juridicalPersonsList = useSelector(juridicalPersons.selectors.selectAll)
    const providersList = useSelector(providers.selectors.selectAll)

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksMoneyFlow}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.MONEY_FLOW_SPENDINGS_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<ISpending, FetchSpendingProps>
                title={'Расходы'}
                urlPathEdit={'/money-flow/spendings/edit'}
                fetchProps={{
                    businessUnit: [],
                    paymentType: [],
                    subSpendingType: [],
                    spendingType: [],
                    createdAt: {
                        after: moment().subtract(30, 'days').startOf('day').toISOString(),
                        before: moment().endOf('day').toISOString()
                    },
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={spendings.actions.fetch}
                selector={spendings.selectors.selectAll}
                mappingFunction={(spending) => ({
                    id: spending.id,
                    businessUnit: spending.businessUnit.name,
                    paymentType: spending.paymentType.text,
                    amount: `${new Intl.NumberFormat('ru-RU').format(spending.amount.amount)} ${spending.amount.currency}`,
                    spendingType: spending.spendingType.name,
                    subSpendingType: spending?.subSpendingType?.name || '',
                    juridicalPerson: spending.juridicalPerson?.name || '',
                    provider: spending.provider?.name || '',
                    comment: spending.comment,
                    actualDate: spending.actualDate,
                })}
                headerExportColumns={[
                    'БЮ', 'Тип оплаты', 'Сумма', 'Статья расхода', 'Подстаться расхода', 'Юр Лицо', 'Поставщик', 'Комментарий', 'Дата списания', 'Дата создания', 'Создатель'
                ]}
                exportMappingFunction={(spending) => ({
                    businessUnit: spending.businessUnit.name,
                    paymentType: spending.paymentType.text,
                    amount: spending.amount.amount,
                    spendingType: spending.spendingType.name,
                    subSpendingType: spending?.subSpendingType?.name || '',
                    juridicalPerson: spending.juridicalPerson?.name || '',
                    provider: spending.provider?.name || '',
                    comment: spending.comment,
                    actualDate: spending.actualDate,
                    createdAt: spending.createdAt,
                    creator: spending.creator.name
                })}
                exportFunction={(params) => {
                    return moneyFlowService.fetchSpendings(params)
                }}
                loading={fetchStatus.isFetching}
                filters={[
                    { name: 'businessUnit', label: 'БЮ', type: 'selectMultiple', items: businessUnitsList.map((item) => ({value: item.id, label: item.name})) },
                    { name: 'paymentType', label: 'Тип оплаты', type: 'selectMultiple', items: paymentTypesList.map((item) => ({value: item.id, label: item.text})) },
                    { name: 'spendingType', label: 'Статья расхода', type: 'selectMultiple', items: spendingTypesList.map((item) => ({value: item.id, label: item.name})) },
                    { name: 'subSpendingType', label: 'Подстатья расхода', type: 'selectMultiple', items: subSpendingTypesList.map((item) => ({value: item.id, label: item.name})) },
                    { name: 'juridicalPerson', label: 'Юр. лицо', type: 'selectMultiple', items: juridicalPersonsList.map((item) => ({value: item.id, label: item.name})) },
                    { name: 'provider', label: 'Поставщик', type: 'selectMultiple', items: providersList.map((item) => ({value: item.id, label: item.name})) },
                    { name: 'actualDate', label: 'Дата списания', type: 'datePeriod' },
                    { name: 'createdAt', label: 'Дата создания', type: 'datePeriod' },
                ]}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true},
                    { field: 'businessUnit', headerName: 'БЮ', flex: 1, sortable: false, renderCell: renderUtils.renderCellExpand },
                    { field: 'paymentType', headerName: 'Тип оплаты', flex: 1, sortable: false },
                    { field: 'amount', headerName: 'Сумма', flex: 1 },
                    { field: 'spendingType', headerName: 'Статья расходов', flex: 1, sortable: false, renderCell: renderUtils.renderCellExpand },
                    { field: 'subSpendingType', headerName: 'Подстатья расходов', flex: 1, sortable: false, renderCell: renderUtils.renderCellExpand },
                    { field: 'juridicalPerson', headerName: 'Юр. лицо', flex: 1, sortable: false, renderCell: renderUtils.renderCellExpand },
                    { field: 'provider', headerName: 'Поставщик', flex: 1, sortable: false, renderCell: renderUtils.renderCellExpand },
                    { field: 'comment', headerName: 'Комментарий', flex: 1, sortable: false, renderCell: renderUtils.renderCellExpand },
                    { field: 'actualDate', headerName: 'Дата списания', flex: 1, type: 'date', renderCell: renderUtils.renderDate },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}