import { IBill } from '../../types'
import { TApiBills } from '../../../services/moneyFlow/types'

export function parseBills(response: TApiBills): IBill[] {
    return response.entities.map((item) => ({
        id: item['@id'],
        name: item.name,
        billType: {
            id: item.type['@id'],
            text: item.type.text
        },
    }))
}