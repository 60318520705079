import { HydraCollectionResponse, IEnum, Money, TApiCollection, TApiEnum } from './types'

export function mapHydraCollection<T>(response: HydraCollectionResponse<T>): TApiCollection<T> {
    return {
        entities: response['hydra:member'],
        totalItems: response['hydra:totalItems'],
    }
}

export function mapCollection<T>(response: Array<T>): TApiCollection<T> {
    return {
        entities: response,
        totalItems: response.length,
    }
}

export function getIdFromIRI(iri: string): string {
    return !!iri && iri.length > 0 ? iri.split('/').slice(-1)[0] : ''
}

export const enumTransformer = (apiObject: TApiEnum): IEnum => ({
    iri: apiObject['@id'],
    id: apiObject.currentValue,
    text: apiObject.text,
})

export const numToMoneyTransformer = (number: number) : Money => ({ amount: number, currency: 'RUB' })

