import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { moneyFlowService } from '../../../services'
import { parseHttpError } from '../../utils'
import { parsePaymentTypes } from './utils'
import { selectors } from './selectors'
import { TPaymentTypesState } from '../../types'
import { paymentTypesAdapter } from './adapter'

const name = 'paymentTypes'

const initialState: TPaymentTypesState = {
    ...paymentTypesAdapter.getInitialState(),
    isFetching: false
}

const fetch = createAsyncThunk(
    `${name}/fetch`,
    async () => {
        return parsePaymentTypes(await moneyFlowService.fetchPaymentTypes())
    },
    {
        serializeError: parseHttpError
    }
)

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetch.pending, (state) => {
                state.isFetching = true
                state.fetchError = undefined
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.isFetching = false
                paymentTypesAdapter.setAll(state, action.payload)
            })
            .addCase(fetch.rejected, (state, action) => {
                state.isFetching = false
                state.fetchError = action.error
            })
})

export const paymentTypes = {
    name: slice.name,
    actions: {
        fetch,
    },
    reducer: slice.reducer,
    selectors,
}