import React from 'react'
import {
    Box,
    Button,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { spendingTypes } from '../../../store/slices'
import { SideBar } from '../../../components/side-bar'
import { navLinksMoneyFlow, renderUtils } from '../../../services'
import { getIdFromIRI } from '../../../services/_common/utils'
import { Link } from 'react-router-dom'
import { DataTable } from '../../../components/dataTable'
import { ROUTES } from '../../../constants'
import { ISpendingType } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'

export function SpendingTypesPage(): JSX.Element {
    const fetchStatus = useSelector(spendingTypes.selectors.fetchStatus)
    const totalItems = useSelector(spendingTypes.selectors.totalItems)

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksMoneyFlow}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.MONEY_FLOW_SPENDING_TYPES_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<ISpendingType, FetchCreatedAtParams & FetchCollectionParams>
                title={'Статьи расходов'}
                urlPathEdit={'/money-flow/spending-types/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={spendingTypes.actions.fetch}
                selector={spendingTypes.selectors.selectAll}
                mappingFunction={(spendingType) => ({
                    id: getIdFromIRI(spendingType.id),
                    name: spendingType.name,
                    expenditureType: spendingType.expenditureType.text,
                    active: spendingType.active,
                })}
                deleteAction={spendingTypes.actions.remove}
                loading={fetchStatus.isFetching}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true },
                    { field: 'name', headerName: 'Название', flex: 1, sortable: true },
                    { field: 'expenditureType', headerName: 'Тип расхода', flex: 1, sortable: false },
                    { field: 'active', headerName: 'Активна', flex: 1, renderCell: renderUtils.renderBoolean, sortable: false },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}