import { IconButtonProps } from '@material-ui/core/IconButton/IconButton'
import { useFormikContext } from 'formik'
import { IconButton } from '@material-ui/core'
import React, { ReactText } from 'react'
import XLSX from 'xlsx'
import moment from 'moment'
import GetAppIcon from '@material-ui/icons/GetApp'
import { ExportFunction, MappingFunction } from './types'

export function ExportButton<T, FetchProps>({
    mappingFunction,
    exportFunction,
    headerExportColumns,
    ...props
}: {
    headerExportColumns: Array<string>,
    mappingFunction: MappingFunction<T>,
    exportFunction: ExportFunction<T, FetchProps>,
} & IconButtonProps): JSX.Element {
    const {
        values,
    } = useFormikContext<FetchProps>()

    const handleExport = () => {
        exportFunction({
            ...values,
            pagination: false
        })
            .then((collection) => {
                const data: Array<Array<string | number | ReactText | undefined | boolean>> = [
                    headerExportColumns,
                ]

                collection.entities.forEach((entity) => {
                    data.push(Object.values(mappingFunction(entity)))
                })

                const worksheet = XLSX.utils.aoa_to_sheet(data)
                const new_workbook = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(new_workbook, worksheet, 'export')
                XLSX.writeFile(new_workbook, `export_${moment().format()}.xlsx`)
            })
    }

    return (<IconButton
        {...props}
        onClick={handleExport}
    >
        <GetAppIcon/>
    </IconButton>)
}