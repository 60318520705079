import React, { useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { SideBar } from '../../../components/side-bar'
import { navLinksProduct } from '../../../services'
import {
    attributes
} from '../../../store/slices'
import { useAppDispatch } from '../../../store'
import { useSelector } from 'react-redux'
import { IAttribute } from '../../../store/types'
import { FetchCollectionParams, FetchCreatedAtParams } from '../../../services/_common'
import { getIdFromIRI } from '../../../services/_common/utils'
import { DataTable } from '../../../components/dataTable'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../constants'


export function AttributesPage(): JSX.Element {

    const dispatch = useAppDispatch()
    const fetchStatus = useSelector(attributes.selectors.fetchStatus)
    const totalItems = useSelector(attributes.selectors.totalItems)

    useEffect(() => {
        dispatch(attributes.actions.fetch({pagination: false}))
    }, [])

    return (
        <Box display="flex">
            <SideBar navLinks={navLinksProduct}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={ROUTES.PRODUCT_ATTRIBUTES_CREATE}
                >
                    Создать
                </Button>
            </SideBar>
            <DataTable<IAttribute, FetchCreatedAtParams & FetchCollectionParams>
                title={'Атрибуты моделей'}
                urlPathEdit={'/product/attributes/edit'}
                fetchProps={{
                    page: 1,
                    perPage: 20,
                }}
                fetchAction={attributes.actions.fetch}
                selector={attributes.selectors.selectAll}
                deleteAction={attributes.actions.remove}
                mappingFunction={(attribute) => ({
                    id: getIdFromIRI(attribute.id),
                    name: attribute.name,
                    description: attribute.description,
                    values: attribute?.values?.join(', '),
                    group: attribute.group ? attribute.group.name : '',
                })}
                loading={fetchStatus.isFetching}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true },
                    { field: 'name', headerName: 'Название', flex: 1, sortable: true },
                    { field: 'description', headerName: 'Описание', flex: 1, sortable: true },
                    { field: 'group', headerName: 'Группа', flex: 1, sortable: true },
                    { field: 'values', headerName: 'Значения', flex: 1, sortable: true },
                ]}
                rowCount={totalItems}
            >
            </DataTable>
        </Box>
    )
}